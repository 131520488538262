import axios from 'axios';

const createDelivery = async (delivery, apiKey) => {

    const deliveriesEndpoint = process.env.REACT_APP_DELIVERIES_ENDPOINT
    const headers = {
        'X-API-Key': apiKey
    }
    try {
        const res = await axios.post(
            `${deliveriesEndpoint}/deliveries`, 
            {delivery: delivery}, 
            {headers:headers}
        )
        const deliveryId = res.data.data._id
        return deliveryId;

    } catch (err) {
        console.log(err);
        return null;
    }
}

export default createDelivery;