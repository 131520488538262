import React from 'react';
import PropTypes from 'prop-types';

import LabelPrinter from '../LabelPrinter.component';

function PrintLabels({apiKey, useLabelPrinter, uploadedDeliveries}) {
    const successfulUploads = uploadedDeliveries.filter(upload => !!upload.id);
    return(
        <>
            <h6>Print Delivery labels</h6>
            <hr/>
            <LabelPrinter 
                hideLabels='true' 
                showDialog='true'                             
                apiKey={apiKey} 
                deliveries={successfulUploads} 
                useLabelPrinter={useLabelPrinter}
            />
        </>
    );
}

PrintLabels.propTypes = {
    apiKey: PropTypes.string,
    useLabelPrinter: PropTypes.bool,
    uploadedDeliveries: PropTypes.arrayOf(PropTypes.string)
}



export default PrintLabels;