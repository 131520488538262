import React from 'react';
import HistoryIcon from '../../../assets/history.svg'
import SidebarItem from './SidebarItem.component';

function HistoryButton() {
    return (
        <a href='/history'>
            <SidebarItem icon={{img:HistoryIcon, alt:"delivery history"}} title="History" eventKey="history">
            </SidebarItem>
        </a>
    )
}

export default HistoryButton;