import React from 'react';
import PropTypes from 'prop-types';
import AddDeliveryModal from '../addDelivery/AddDeliveryModal.component';
import NewDeliveryIcon from '../../../assets/new-delivery.svg';
import { Col, ListGroup, Row } from 'react-bootstrap';

function AddDeliveries({user, deliveries, refreshDeliveries}) {
    return(
        <AddDeliveryModal 
            user={user} 
            deliveries={deliveries}
            refreshDeliveries={refreshDeliveries}
        >
            <ListGroup.Item className='Submenu p-2'>
                <Row>
                    <Col>
                    <strong>
                        <em>                                
                            Add deliveries
                        </em>
                    </strong>
                    &nbsp;
                    &nbsp;
                    <img
                        src={NewDeliveryIcon}
                        alt="plus sign"
                        width="13vh"
                    />
                    </Col>
                </Row>
            </ListGroup.Item>
        </AddDeliveryModal>
    )
}

AddDeliveries.propTypes = {
    user: PropTypes.object,
    deliveries: PropTypes.arrayOf(PropTypes.object),
    refreshDeliveries: PropTypes.func
}

export default AddDeliveries;