
import React from 'react';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Day = ({val, SelectedDateUpdate, currentMonth, prevMonth, nextMonth, selectedDate}) => {
    
    const DayUpdater = () => {
        switch (currentMonth) {
            case 1:
                prevMonth()
                break;
            case 2:
                nextMonth()
                break;
        }
        SelectedDateUpdate(new Date(val))
    }  

    const highlightDate = () => {

        return <div className ={`${!currentMonth ? 'day selectedDay' : 'invalidDay'}`} onClick = {DayUpdater}>
            {val.getDate()}
        </div>
    }

    const nonHighlightDate = () => {
        return <div className ={`${!currentMonth ? 'day' : 'invalidDay'}`} onClick = {DayUpdater}>
            {val.getDate()}
        </div>
    }

    return (
        <>
        {  
            (val.getFullYear() === selectedDate.getFullYear() && val.getMonth() === selectedDate.getMonth()
            && val.getDate() === selectedDate.getDate()) ? highlightDate() : nonHighlightDate()
        }
        </>
    )
}

Day.propTypes = {
    val: PropTypes.instanceOf(Date).isRequired,
    SelectedDateUpdate: PropTypes.func.isRequired,
    currentMonth: PropTypes.number.isRequired,
    prevMonth: PropTypes.func.isRequired,
    nextMonth: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired

}

export default Day