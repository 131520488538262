const changePassword = async (oldPassword, newPassword) => {
    const passwords = {
        user: {
            oldPassword,
            newPassword
        }
    }

    const loginEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + '/user/password'
    
    let response = await fetch(
        loginEndpoint, {
            method:'PUT',
            credentials:'include',
            headers: {
                Accept:'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(passwords)
        })

    if (response.ok) {
        return true;
    } else {
        console.log("HTTP ERROR: "+ response.status);
        return false;
    }
}

export default changePassword;