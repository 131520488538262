import React, {useState} from 'react'; 
import PropTypes from 'prop-types';
import { Col, Row, Card, Form, Button, Alert } from 'react-bootstrap';

import Layout from '../_common/Layout.component';
import Logo from '../../assets/logo.png';

import doLogin from '../../services/doLogin.service';

function Login({checkIsLoggedIn}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const login = async (event) => {
        const loggedIn = await doLogin(email, password);
        if (loggedIn === true) {
            setError('');
            setShowError(false);
            checkIsLoggedIn();
        }
        else {
            console.log(loggedIn);
            if(loggedIn === "Account suspended") {
                setError(loggedIn + ", please call (647) 56-TAYZA [82992] for more information")
            }
            else {
                setError(loggedIn);
            }
            setShowError(true);
        }
    };

    const minRequirements = () => {
        return (
            "Passwords are a minimum of eight characters, and must include one lowercase character, one uppercase character, one number, and one special character"
        )
    }

    return (
        <Layout>
            <Row className="loginForm align-items-center justify-content-center" >
                <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Card bg="primary">
                        <Card.Img 
                            variant="top" 
                            src={Logo} 
                        />
                        <Card.Body variant="primary">
                            <Form >
                                <Form.Group>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Email" 
                                        value={email}
                                        onChange={event => {
                                            setEmail(event.target.value);
                                            setShowError(false);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Password" 
                                        value={password}
                                        onChange={event => {
                                            setPassword(event.target.value);
                                            setShowError(false);
                                        }}
                                    />
                                </Form.Group>
                                <Alert style={{fontSize: '10px'}} show={showError} variant='secondary'>
                                    {error}
                                    {error === "Invalid password" ? <br></br> : null}
                                    {error === "Invalid password" ? minRequirements() : null}
                                 </Alert>
                                <Button 
                                    onClick={login}
                                    variant='secondary' 
                                    block 
                                >Log In</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

Login.propTypes = {
    checkIsLoggedIn: PropTypes.func.isRequired
}

export default Login;