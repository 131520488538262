import React, { useState, useEffect } from 'react';
import Layout from '../../_common/Layout.component';

import MoreDetailsOverview from './MoreDetailsOverview.component';
import MoreDetailsFilter from './MoreDetailsFilters.component';
import MoreDetailsHeader from './MoreDetailsHeader.component';

import { Col } from 'react-bootstrap';

function MoreDetails({setShowMoreDetails, moreDetailsDelivery, setMoreDetailsDelivery, deliveries, user, setStartDate, setEndDate, setSortByFilter, setMoreDetailsUpdate}) {
    useEffect(() => {
        console.log(setShowMoreDetails)
    }, []);
    return (
        <Layout style={{ overflow: 'hidden' }}>
            <MoreDetailsHeader setShowMoreDetails={setShowMoreDetails}/>
            <Col xs={3} style={{ float: 'left' }}>
                <MoreDetailsFilter
                    setStartDate={setStartDate} 
                    setEndDate={setEndDate}
                    setSortByFilter={setSortByFilter}
                    deliveries={deliveries}
                    moreDetailsDelivery={moreDetailsDelivery}
                    setMoreDetailsDelivery={setMoreDetailsDelivery}
                />
            </Col>
            <Col xs={9} style={{ display: 'inline', float: 'left', paddingLeft: '2%' }}>
                <MoreDetailsOverview
                    setShowMoreDetails={setShowMoreDetails} 
                    moreDetailsDelivery={moreDetailsDelivery}
                    deliveries={deliveries}
                    user={user}
                    setMoreDetailsUpdate={setMoreDetailsUpdate}
                />
            </Col>
            <Col>
            </Col>
        </Layout>
    );
}

export default MoreDetails;
