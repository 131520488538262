import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, ButtonGroup, Form, Card, Badge} from 'react-bootstrap';

import AddPickupAddress from './AddPickupAddress.component';

import doChangeUserInfo from '../../services/doChangeUserInfo.service';

function EditPickupAddresses({addresses, refreshUser}) {
    
    const [addressList, setAddressList] = useState(addresses);

    useEffect(() => {
        setAddressList(addresses);
    }, [addresses]);

    const addAddress = newAddress => {
        const nextAddressList = [...addressList, newAddress];
        submitChanges(nextAddressList);
    }

    const removeAddress = removeIndex => {
        if (addressList.length > 1) {
           let nextAddressList = [...addressList];
           nextAddressList.splice(removeIndex, 1);

           submitChanges(nextAddressList);
        }
    }

    const setDefaultAddress = defaultIndex => {
        const nextAddressList = addressList.map((address, addressIndex) => {
            address.default = (defaultIndex === addressIndex);
            return address;
        })

        submitChanges(nextAddressList);
    }

    const submitChanges = async addresses => {
        const updateResult = await doChangeUserInfo({address: addresses});
        if (updateResult) refreshUser();
        else alert("An unknown error has occurred");
    }


    return (
        <>
            <Row>
                <Col>
                    <Form.Label>
                        <h6>Pickup Addresses</h6>
                    </Form.Label>
                </Col>
            </Row>

            {
                addressList.map((address, index) => (
                    <Card key={index}>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Text>
                                        {(address.unit) ? `${address.unit} - `: ''}
                                        {address.number}&nbsp;{address.street}
                                        <br/>
                                        {address.city}, {address.province}, {address.country}
                                        <br/>
                                        {address.postalCode}
                                    </Card.Text>
                                </Col>
                                <Col align='right'>
                                    {
                                        (address.default)
                                        ? 
                                        <Card.Title>
                                            <Badge variant='secondary' pill>Default&nbsp;Address</Badge>
                                        </Card.Title>
                                        :
                                        <Card.Text>
                                            <ButtonGroup>
                                                <Button
                                                    variant='primary'
                                                    onClick={()=> setDefaultAddress(index)}
                                                >
                                                    Make&nbsp;Default
                                                </Button>
                                                <Button
                                                    variant='primary'
                                                    onClick={()=> removeAddress(index)}
                                                    disabled={(addressList.length <= 1)}
                                                >
                                                    Remove
                                                </Button>
                                            </ButtonGroup>
                                        </Card.Text>
                                    }

                                </Col>
                            </Row>
                        </Card.Body>

                    </Card>
                ))
            }
            <br/>
            <AddPickupAddress addAddress={addAddress}/>
            
        </>
    )
}

EditPickupAddresses.propTypes = {
    addresses: PropTypes.arrayOf(PropTypes.object),
    refreshUser: PropTypes.func,
}

export default EditPickupAddresses;