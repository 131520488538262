import Month from './CurrentDeliveriesMonth.component'
import TimePicker from './CurrentDeliveriesTimePicker.component'
import React from 'react';
import PropTypes from 'prop-types';

const Dates = ({date, SelectedDateUpdate, TimeUpdate, updateDate, monthUpdate, selectedDate}) => {
    return (
        <div className = "dates" >
            <Month 
                dateProp = {date} SelectedDateUpdate = {SelectedDateUpdate} 
                updateDate = {updateDate} monthUpdate = {monthUpdate} 
                selectedDate = {selectedDate}
            />
            <TimePicker TimeUpdate = {TimeUpdate} selectedDate = {selectedDate}/>
        </div>
    )
}

Dates.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    SelectedDateUpdate: PropTypes.func.isRequired,
    TimeUpdate: PropTypes.func.isRequired,
    updateDate: PropTypes.func.isRequired,
    monthUpdate: PropTypes.bool.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
}
export default Dates