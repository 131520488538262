import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ProgressBar, ListGroup, Table, Card, Spinner, Button, ButtonGroup, Form, Dropdown, DropdownButton, FormCheck, FormControl } from 'react-bootstrap';

import CurDelPaginationControls from './CurrentDeliveriesPagination.component';
import CurDelLabelButton from './CurrentDeliveriesLabelButton.component';
import CurrentDeliveryStatusIndicator from './CurrentDeliveriesStatus.component';

import deleteDelivery from '../../services/deleteDelivery.service';
import validateAllPickups from '../../services/validateAllPickups.service';

import readyDelivery from '../../services/readyDelivery.service';
import unreadyDelivery from '../../services/unreadyDelivery.service';


function CurrentDeliveriesList({ deliveries, user, refreshDeliveriesAndGiftSenders, page, lastPage, setPage, setSortByFilter,
                                 pageLimit, setPageLimit, numDeliveries, setShowMoreDetails, setMoreDetailsDelivery, moreDetailsDelivery }) {
    const [selectedList, setSelectedList] = useState([]);
    const [warning, setWarning] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [progressLabel, setProgressLabel] = useState(0);
    const [originalListLength, setOriginalListLength] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [pickupButton, setPickupButton] = useState('');
    const [pickupModal, setPickupModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        // READYFORPICKUP DISABLE pickupToggle();
        disableToggle();
    }, [selectedList]);

    useEffect(() => {
        if (deliveries) {
            setShowFooter(true);
        }
        else {
            setShowFooter(false);
        }
    }, [deliveries]);
  
    const pickupToggle = () => {
        let allPickup = selectedList.length > 0;
        let allSubmittedOrPickup = selectedList.length > 0; 

        selectedList.map((delivery) => {
            if (["completed", "failed", "inProgress", "active"].includes(delivery.status)) {
                allPickup = false; 
                allSubmittedOrPickup = false;
            }
            if(delivery.status === "submitted") {
                allPickup = false;
            }
        })
        
        if(allPickup) {
            setPickupButton('unready');
        }
        else if (allSubmittedOrPickup) {
            setPickupButton('ready')
        }
        else {
            setPickupButton('');
        }
    }

    const disableToggle = () => {
        let count = 0;
        selectedList.map((delivery) => {
            if (["completed", "failed", "inProgress"].includes(delivery.status)) {
                setDisableButton(true);
                count++;
            }
        })
        if (count === 0) {
            setDisableButton(false);
        }
    }

    const handleSelected = (delivery) => {
        if (selectedList.filter(item => item.id === delivery.id).length === 1) {
            setSelectedList(prevList => prevList.filter(item => item.id !== delivery.id));
        }
        else {
            setSelectedList(prevList => prevList.concat(delivery));
        }
    }

    /* READYFORPICKUP DISABLE */
    /*
    {
                    -- AFTER SELECT ALL BUTTON -- 
                    (pickupButton === '') 
                    ?
                        <h5 style={{paddingLeft:'40px', color:'#808080'}}>
                        AVAILABLE FOR PICKUP
                        </h5>
                    :
                    (pickupButton === 'ready')
                    ?
                        <div style={{paddingLeft:'28px', position:'relative', top:'-10px'}}> 
                        <Button data-testid='readyButton' variant='danger' style={{fontSize: '20px', color:'black'}} onClick={openPickupModal}>
                            AVAILABLE FOR PICKUP
                        </Button>
                        </div>
                    :
                        <div style={{paddingLeft:'28px', position:'relative', top:'-10px'}}> 
                        <Button data-testid='unreadyButton' variant='danger' style={{fontSize: '20px', color:'black'}} onClick={openPickupModal}>
                            UNDO AVAILABLE FOR PICKUP
                        </Button>
                        </div>
                    }

                    -- BEFORE DELETE MODAL -- 
                    <Modal show={pickupModal} onHide={closePickupModal}>
                <Modal.Header closeButton>
                    {inProgress ? `Updating ${originalListLength} deliveries`
                    :
                    `Are you sure you want to update ${originalListLength} ${originalListLength === 1 ? "delivery" : "deliveries"}?`
                    }
                </Modal.Header>
                <Modal.Body>
                    { inProgress 
                    ? 
                    <ProgressBar  variant="primary" animated="true" label={`${progressLabel} / ${originalListLength}`} now={progressLabel/originalListLength * 100}/>
                    :
                    selectedList.map((delivery) => 
                        pickupButton === 'ready'
                        ?
                            delivery.status === 'submitted'
                            ?
                                <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <strong>{delivery.recipientName}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <em>
                                            {   (delivery.addressUnit) 
                                                ? delivery.addressUnit + ' - ' + delivery.addressNumber + ' ' + delivery.addressStreet
                                                : delivery.addressNumber + ' ' + delivery.addressStreet
                                            }
                                        </em>
                                    </Col>
                                </Row>
                                </ListGroup.Item>
                            :
                            null             
                        :
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <strong>{delivery.recipientName}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <em>
                                            {   (delivery.addressUnit) 
                                                ? delivery.addressUnit + ' - ' + delivery.addressNumber + ' ' + delivery.addressStreet
                                                : delivery.addressNumber + ' ' + delivery.addressStreet
                                            }
                                        </em>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        updatePickupStatus();
                    }}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={closePickupModal}>
                        No
                    </Button>
                </Modal.Footer>
        </Modal>
    */

    const updatePickupStatus = () => {
        setInProgress(true);
        Promise.all (selectedList.map(async (delivery) => {
            if(pickupButton === 'ready') {
                if(delivery.status === 'submitted') {
                    return readyDelivery(delivery.id, user.apiKey).then(() => {
                        setProgressLabel(prevLabel => prevLabel + 1);
                    })
                }
            }
            else {
                return unreadyDelivery(delivery.id, user.apiKey).then(() => {
                    setProgressLabel(prevLabel => prevLabel + 1);
                })
            }
        }))
        .then(async () => {
            selectedList.forEach((delivery) => {
                if(pickupButton === 'ready') {
                    if(delivery.status === 'submitted') {
                        delivery.status = 'pickupReady';
                    }
                }
                else {
                    delivery.status = 'submitted'
                } 
            })
            await validateAllPickups(user.apiKey);
            refreshDeliveriesAndGiftSenders();
            closePickupModal();
            // READYFORPICKUP DISABLE pickupToggle();
            setInProgress(false);
            setProgressLabel(0);
        })
        .catch(err => alert(err));
    }

    const removeDelivery = () => {
        setInProgress(true);
        Promise.all(
            selectedList.map(async (delivery) => {
                return deleteDelivery(delivery.id, user.apiKey).then(() => {
                    setProgressLabel(prevLabel => prevLabel + 1);
                    setSelectedList(prevList => prevList.filter(item => item.id !== delivery.id));
                    refreshDeliveriesAndGiftSenders();
                });
            }))
            .then(async () => {
                await validateAllPickups(user.apiKey);
                closeWarning();
                setInProgress(false);
                setProgressLabel(0);
            })
            .catch(err => alert(err));
    }

    const closePickupModal = () => {
        setPickupModal(false);
    }

    const openPickupModal = () => {
        if(pickupButton === 'ready'){
            setOriginalListLength(selectedList.filter((delivery) => delivery.status === 'submitted').length)
        }
        else {
            setOriginalListLength(selectedList.length);
        }
        setPickupModal(true);
    }

    const openWarning = () => {
        setOriginalListLength(selectedList.length);
        setWarning(true);
    }

    const closeWarning = () => {
        setWarning(false);
    }

    const handleSelectAll = () => {
        if (!selectAll) { setSelectedList(deliveries); }
        else { setSelectedList([]); }
        setSelectAll(prev => !prev);
    }

    return (
        <Row className='wrapper'>
            <Card className="CurrentDeliveriesTable">
                <div className="CurrentDeliveriesTableHeader">
                    <h3 style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                        Summary Of Deliveries
                        <div style={{ float: 'right', justifyItems: 'right' }} className="noPointer">
                            <CurDelPaginationControls
                                page={page}
                                lastPage={lastPage}
                                setPage={setPage}
                            >
                        </CurDelPaginationControls>
                    </div>
                </h3>
                <hr />
                <div style={{paddingLeft:'20px', marginTop:'10px', display:'flex', paddingTop:'10px'}} className="noPointer">
                    <h5 data-testid='selectAll' style={{textDecoration: 'underline'}} onClick={handleSelectAll}>
                        SELECT ALL
                    </h5>
                    { 
                        (selectedList.length === 0)
                        ?
                        <h5 style={{ paddingLeft: '40px', color: '#808080' }}>
                            LABELS
                        </h5>
                        :
                        (user) ?
                            <CurDelLabelButton
                                apiKey={user.apiKey}
                                deliveries={selectedList.filter(upload => !!upload.id)}
                                useLabelButton={user.preferences.useLabelPrinter ? true : false}
                                disabled={selectedList.length === 0}
                            />
                        :
                        null
                    }
                    {
                        (selectedList.length > 0 && !disableButton)
                        ?
                        <div style={{ paddingLeft: '28px', position: 'relative', top: '-10px' }}>
                            <Button variant='danger' style={{ fontSize: '20px', color: 'black' }} onClick={openWarning}>
                                DELETE
                            </Button>
                        </div>
                        :
                        <h5 style={{ paddingLeft: '40px', color: '#808080' }}>
                            DELETE
                        </h5>
                    }
                </div>
                    {
                    selectedList.length > 0
                        ?
                        <div style={{ float: 'right', justifyItems: 'right', display: 'flex', top: '90px', marginTop: '-60px' }}>
                            <DropdownButton
                                variant="white"
                                title="SORT BY"
                            >
                                <Dropdown.Item onClick={() => { setSortByFilter('RecipientName') }}>
                                    Recipient Name
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSortByFilter('MostRecent') }}>
                                    Most Recent
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSortByFilter('OldestToNewest') }}>
                                    Oldest to Newest
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSortByFilter('Status') }}>
                                    Status
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                        :
                        <div style={{ float: 'right', justifyItems: 'right', display: 'flex', top: '90px', marginTop: '-40px' }}>
                            <DropdownButton
                                variant="white"
                                title="SORT BY"
                            >
                                <Dropdown.Item onClick={() => { setSortByFilter('RecipientName') }}>
                                    Recipient Name
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSortByFilter('MostRecent') }}>
                                    Most Recent
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSortByFilter('OldestToNewest') }}>
                                    Oldest to Newest
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSortByFilter('Status') }}>
                                    Status
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    }
                </div>
                {
                    (!deliveries) ? (
                        <div style={{ top: '47%', left: '47%', position: 'relative' }}>
                            <Spinner
                                animation='border'
                                role='status'
                                variant='secondary'
                                align='center'
                                className='p-3 m-3'
                            />
                        </div>
                    ) : (
                        <div style={{ height: '80vh', overflowY: 'scroll' }}>
                            <Table bordered hover striped style={{ marginTop: '150px' }}>
                                <tbody>
                                    {(deliveries) ? deliveries.map((delivery, index) => (
                                        <tr>
                                            {
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    <FormCheck
                                                        id={index + 1}
                                                        custom
                                                        label=""
                                                        checked={selectedList.filter(item => item.id === delivery.id).length === 1}
                                                        onChange={() => {
                                                            handleSelected(delivery);
                                                        }
                                                        }>
                                                    </FormCheck>
                                                </td>
                                            }
                                            <td>
                                                <b>{delivery.recipientName}</b>
                                                <br />
                                                {`${delivery.addressNumber} ${delivery.addressStreet}`}
                                                <br />
                                                {`${delivery.city}, ${delivery.province}, ${delivery.country}, ${delivery.postalCode}`}
                                            </td>
                                            <td>
                                                <b>Date: </b>
                                                {delivery.created.toDateString()}
                                                <br />

                                                <b>Time: </b>
                                                {delivery.created.toLocaleTimeString()}
                                                <br />

                                                <b>Phone: </b>
                                                {delivery.recipientPhone}
                                                <br />
                                            </td>
                                            <td>
                                                <b>Zone: </b>
                                                {`${delivery.zone.zone}${delivery.zone.subzone}`}
                                                <br />

                                                <b>Quantity: </b>
                                                {delivery.quantity}
                                                <br />

                                                <b>Gift Sender: </b>
                                                {delivery.giftSender}
                                                <br />
                                            </td>
                                            <td style={{width: "15%", position: 'relative'}}>
                                                <b>Notes: </b>
                                                {delivery.deliveryNotes}
                                                <br />
                                                <a style = {{position: 'absolute', right: '0'}} className="underlineHover" onClick={() => {
                                                    window.open(`/tracking/${delivery.id}`, "_blank").focus()
                                                }}>
                                                    <b>{`Tracking >>`}</b>
                                                </a>
                                                <a style={{position: 'absolute', right: '0', bottom: '0'}} className="underlineHover" onClick={() => {
                                                    setMoreDetailsDelivery(delivery)
                                                    setShowMoreDetails(true)
                                                    console.log(delivery)
                                                    }}>
                                                    <b>{`More info >>`}</b>
                                                </a>
                                            </td>
                                            <td style={{ textAlign: 'center', paddingTop: '30px' }}>
                                                {delivery.status.charAt(0).toUpperCase() + delivery.status.slice(1)}
                                                <CurrentDeliveryStatusIndicator
                                                    status={delivery.status}
                                                />
                                            </td>
                                        </tr>
                                    )
                                    ) : []}
                                </tbody>
                            </Table>
                        </div>
                    )
                }
                <div style={{ paddingTop: '15px', paddingRight: '20px', paddingLeft: '20px', float: 'left' }}>
                    <FormControl
                        data-testid="deliveryForm"
                        size='sm'
                        placeholder={pageLimit}
                        style={{ width: '7ch' }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                const num = parseInt(e.target.value);
                                if (num > 0 && !Number.isNaN(num)) {
                                    setPageLimit(num)
                                }
                            }
                        }
                        } >
                  </FormControl>
              </div>
              <p style={{ paddingTop: '20px', marginLeft: '-10px', float: 'left' }}>
                  <b>
                      DELIVERIES PER PAGE
                  </b>
              </p>

              <p style={{ paddingTop: '20px', paddingRight: '20px', float: 'right' }}>
                  <b>
                      {(showFooter) ? (`SHOWING ${deliveries.length > 0 ? page * pageLimit + 1 : 0} - ${(page === lastPage) ? ((lastPage) * pageLimit + deliveries.length) : (page + 1) * pageLimit} OF ${numDeliveries}`) : null}
                  </b>
              </p>
          </Card>
        <Modal show={warning} onHide={closeWarning}>
                <Modal.Header closeButton>
                    {inProgress ? `Deleting ${originalListLength} deliveries`
                        :
                        `Are you sure you want to delete ${originalListLength} ${originalListLength === 1 ? "delivery" : "deliveries"}?`
                    }
                </Modal.Header>
                <Modal.Body>
                    {inProgress ? <ProgressBar variant="primary" animated="true" label={`${progressLabel} / ${originalListLength}`} now={progressLabel / originalListLength * 100} />
                        :
                        selectedList.map((delivery) =>
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <strong>{delivery.recipientName}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <em>
                                            {(delivery.addressUnit)
                                                ? delivery.addressUnit + ' - ' + delivery.addressNumber + ' ' + delivery.addressStreet
                                                : delivery.addressNumber + ' ' + delivery.addressStreet
                                            }
                                        </em>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        removeDelivery();
                    }}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={closeWarning}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>

    )
};

CurrentDeliveriesList.propTypes = {
    deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    refreshDeliveriesAndGiftSenders: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    showFooter: PropTypes.bool,
    setSortByFilter: PropTypes.func.isRequired,
    pageLimit: PropTypes.number.isRequired,
    setPageLimit: PropTypes.func.isRequired,
    numDeliveries: PropTypes.number.isRequired
};


export default CurrentDeliveriesList;