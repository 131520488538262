import React, { useState, useEffect } from 'react';
import {Row, Col, Button, ButtonGroup, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import doChangeUserInfo from '../../services/doChangeUserInfo.service';
function EditCompanyName({companyName, refreshUser}) {
    const [prevCompanyName, setPrevCompanyName] = useState(companyName);
    const [nextCompanyName, setNextCompanyName] = useState(companyName);

    useEffect(() => {
        setPrevCompanyName(companyName);
        setNextCompanyName(companyName);
    }, [companyName]);

    const update = async () => {
        const updateObject = { companyName: nextCompanyName };
        const update = await doChangeUserInfo(updateObject);
        if (update) refreshUser();
    }

    const companyNameUnchanged = (prevCompanyName === nextCompanyName);

    return (
        <Form.Group>
            <Row>
                <Col>
                    <Form.Label>
                        <h6>Company Name</h6>
                    </Form.Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control 
                        type='text'
                        data-testid='newCompany'
                        value={nextCompanyName}
                        onChange={(event) => setNextCompanyName(event.target.value)}
                    />
                </Col>
                <Col xs='auto'>
                    <ButtonGroup>
                        <Button 
                            variant='primary' 
                            data-testid='undoButton'
                            disabled={companyNameUnchanged}
                            onClick={() => setNextCompanyName(prevCompanyName)}
                        >
                            Undo
                        </Button>
                        <Button 
                            variant='secondary' 
                            data-testid='saveButton'
                            disabled={companyNameUnchanged}
                            onClick={update}
                        >
                            Save
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Text className="text-muted">
                        This name will be displayed to customers receiving
                        deliveries from your account
                    </Form.Text>
                </Col>
            </Row>   
        </Form.Group>
    )
}

EditCompanyName.propTypes = {
    companyName: PropTypes.string,
    refreshUser: PropTypes.func.isRequired,
}

export default EditCompanyName;