const doLogout = async () => {

    const loginEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + '/user/login'
    
    let response = await fetch(
        loginEndpoint, {
            method:'DELETE',
            credentials:'include',
            headers: {
                Accept:'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({})
        })

    if (response.ok) {
        return true;
    } else {
        console.log("HTTP ERROR: "+ response.status);
        return false;
    }
}

export default doLogout;