import React, { useState, useEffect} from 'react'
import Dates from './CurrentDeliveriesDates.component'
import PropTypes from 'prop-types';

const StartDateTimePicker = ({setStartDate, toggleStartDateTime, setToggleStartDateTime}) => {
    const [DatePicker, setDatePicker] = useState(false)
    const [date, setDate] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [monthUpdate, setMonthUpdate] = useState(false)
    const [onLoad, setOnLoad] = useState(false)

    useEffect(() => {
        if(monthUpdate)
        setMonthUpdate(false)
    }, [date])

    useEffect(() => {
        setDate(selectedDate)
        setOnLoad(true)
        if(onLoad)
        setStartDate(new Date(selectedDate))
    }, [selectedDate])

    useEffect(() => {
        setDate(new Date(selectedDate))
    }, [DatePicker])

    useEffect(() => {
        setDatePicker(toggleStartDateTime)
    }, [toggleStartDateTime])
    
    const updateDate = (updatedMonth, updatedYear) => {
        let tempDate = new Date(date)
        tempDate.setDate(1)
        tempDate.setMonth(updatedMonth)
        tempDate.setYear(updatedYear)
        setDate(tempDate)

    }

    const TimeUpdate = (hours, minutes) => {
        let tempSelectedDate = new Date(selectedDate)
        tempSelectedDate.setHours(hours)
        tempSelectedDate.setMinutes(minutes)
        if(selectedDate.getMonth() !== tempSelectedDate.getMonth())
        {
            setMonthUpdate(true)
        }
        setSelectedDate(tempSelectedDate)
    }

    const updateSelectedDate = (updDate) => {
            const selHours = date.getHours()
            const selMins = date.getMinutes()
            let tempDate = new Date(updDate)
            tempDate.setHours(selHours)
            tempDate.setMinutes(selMins)
            setSelectedDate(tempDate)
    }

     const formatDisplayDate  = (dateToFormat) => {
        let displayDate = dateToFormat.toLocaleString('default', {month: 'short'} ) + ' ' + dateToFormat.getDate()
        + ' ' + dateToFormat.getFullYear()

        let displayTime =  dateToFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        
        return displayDate + ' ' + displayTime
    }

        return (
            <div className="current-date-picker">
                <div className="current-selected-date"  onClick = {() => {
                    setToggleStartDateTime(!toggleStartDateTime)
                }}>
                    {formatDisplayDate(selectedDate)}
                </div>

                {DatePicker ? 
                <Dates date = {date} SelectedDateUpdate = {updateSelectedDate}
                updateDate = {updateDate} TimeUpdate = {TimeUpdate}
                monthUpdate = {monthUpdate} selectedDate = {selectedDate}                
                /> 
                : <></>}
            </div>
        )
}

StartDateTimePicker.propTypes = {
    setStartDate: PropTypes.func.isRequired,
    toggleStartDateTime: PropTypes.bool.isRequired,
    setToggleStartDateTime: PropTypes.func.isRequired
};

export default StartDateTimePicker
