import React, {useState, useEffect} from 'react'; 
import {Row, Col, Card, Accordion} from 'react-bootstrap'


function DeliveryMoreInfo (delivery) {

    const[actualDelivery, setActualDelivery] = useState({});
    const [deliveryStatus, setDeliveryStatus] = useState("");

    useEffect(() => {
        console.log(delivery.delivery)
        setActualDelivery(delivery.delivery);
        refreshStatusUpdate();
    }, [delivery])

    const refreshStatusUpdate = () => {
        console.log(delivery.delivery)
        if(Object.keys(delivery.delivery).length != 0){
            let status;
            if (delivery.delivery.status.failed || delivery.delivery.status.completed) {
                if (delivery.delivery.status.failed) { 
                    status = 'failed'; 
                }
                if (delivery.delivery.status.completed) { 
                    status = 'completed'; 
                }
            }
            else if (delivery.delivery.status.inProgress) { 
                status = 'inProgress'; 
            }
            else if (delivery.delivery.status.active) {
                let activeTime = new Date(delivery.delivery.status.active)
                let submittedTime = new Date(delivery.delivery.status.submitted)
                status = ( activeTime > submittedTime ) 
                ?  'active' 
                :  'submitted';
            }      
            else if (delivery.delivery.status.submitted) { 
                status = 'submitted';
            }

            setDeliveryStatus(status);
        }
    }

    return(
        <React.Fragment>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" style={{height: "3.5em", backgroundColor: "#dbdcdf"}}>
                        <div className="moreInfoHeader">
                            <p>MORE INFO</p>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{backgroundColor: "#f3f4f8"}}>
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                    <p><b>From: </b>{actualDelivery ? actualDelivery.sender : null}</p>
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    <p><b>To: </b>{actualDelivery ? actualDelivery.recipient : null}</p>
                                </Col>
                            </Row>
                            { (actualDelivery.status && actualDelivery.status.inProgress && actualDelivery.status.inProgress != actualDelivery.status[deliveryStatus])
                            ?
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                    <p>In Progress</p>
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    <p>{new Date(actualDelivery.status.inProgress).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                                </Col>
                            </Row>
                            :
                            null
                            }
                            { (actualDelivery.status && actualDelivery.status.active && actualDelivery.status.active != actualDelivery.status[deliveryStatus])
                            ?
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                    <p>Active</p>
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    <p>{new Date(actualDelivery.status.active).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                                </Col>
                            </Row>
                            :
                            null
                            }
                            { (actualDelivery.status && actualDelivery.status.submitted && actualDelivery.status.submitted != actualDelivery.status[deliveryStatus])
                            ?
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                    <p>Submitted</p>
                                </Col>
                                <Col style={{textAlign: 'center'}}>
                                    <p>{new Date(actualDelivery.status.submitted).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                                </Col>
                            </Row>
                            :
                            null
                            }
                            <Row style={{display: 'inline-block', paddingLeft: '5%', width: '100%', paddingTop: '1%'}} className="driverUpdate">
                                <Col>
                                    {((actualDelivery.completionPhotos && actualDelivery.completionPhotos[0])|| actualDelivery.completionNotes) 
                                    ?
                                    <div>
                                        <p style={{margin: '0'}}><b>Driver Notes: </b> {`${(actualDelivery.completionNotes) ? (actualDelivery.completionNotes) : ""}`}</p>
                                        <p style={{margin: '0'}}><b>Driver Photos: </b></p>
                                        {(actualDelivery.completionPhotos) ? 
                                            actualDelivery.completionPhotos.map((photo, index) => {
                                                return (
                                                    <div style={{width: '20%', height: '30%', float:'left'}}>
                                                        <img src={photo} style={{width: '90%'}} className="driverUpdateImages"/>
                                                    </div>
                                                )
                                            }) 
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </React.Fragment>
    );
}

export default DeliveryMoreInfo;