import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";

import Settings from '../../assets/settings.svg';
import ProfileModal from '../profile/ProfileModal.component';
import SettingsModal from '../settings/SettingsModal.component';
import LogoutModal from '../logout/Logout.component';

class SettingsMenu extends Component {
    render = () => {
        return (
        <Dropdown className="SettingsMenu">
            <Dropdown.Toggle variant="primary" className="SettingsMenuToggle">
                <img 
                    src={Settings}
                    width='20vh'
                    alt="Settings"
                />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <ProfileModal 
                    user={this.props.user}
                    refreshUser={this.props.refreshUser}
                >
                    <Dropdown.Item>Profile</Dropdown.Item>
                </ProfileModal>
                <SettingsModal
                    user={this.props.user}
                    refreshUser={this.props.refreshUser}
                >
                    <Dropdown.Item>Settings</Dropdown.Item>
                </SettingsModal>
                <LogoutModal checkIsLoggedIn={this.props.checkIsLoggedIn}>
                    <Dropdown.Item>Log out</Dropdown.Item>
                </LogoutModal>
            </Dropdown.Menu>
        </Dropdown>
        )
    }
}

export default SettingsMenu;