import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ProgressBar, ListGroup, Table, Card, Spinner, Button, ButtonGroup, Form, Dropdown, DropdownButton, FormCheck, FormControl } from 'react-bootstrap';
import Layout from '../../_common/Layout.component';

import CurrentDeliveryStatusIndicator from '../CurrentDeliveriesStatus.component';
import MoreDetailsMap from './MoreDetailsMap.component';
import CurDelLabelButton from '../CurrentDeliveriesLabelButton.component';
import deleteDelivery from '../../../services/deleteDelivery.service';
import validatePickup from '../../../services/validatePickup.service';

import readyDelivery from '../../../services/readyDelivery.service';
import unreadyDelivery from '../../../services/unreadyDelivery.service';
import validateAllPickups from '../../../services/validateAllPickups.service';


function MoreDetailsOverview({setShowMoreDetails, moreDetailsDelivery, deliveries, user, setMoreDetailsUpdate}) {
    const [warning, setWarning] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [pickupButton, setPickupButton] = useState('')
    const [pickupModal, setPickupModal] = useState(false);
    const [progressLabel, setProgressLabel] = useState(0);

    useEffect(() => {
        // console.log(deliveries)
        // console.log(moreDetailsDelivery)
        // pickupToggle()
    }, [moreDetailsDelivery]);

    const removeDelivery = async () => {
        try{
            setInProgress(true);
            validatePickup(moreDetailsDelivery.id, user.apiKey);
            await deleteDelivery(moreDetailsDelivery.id, user.apiKey);
            closeWarning();
            setInProgress(false);
            setMoreDetailsUpdate(true);
            setShowMoreDetails(false);
        }
        catch(err) {
            alert(err)
        }
    }

    const openWarning = () => {
        setWarning(true);
    }

    const closeWarning = () => {
        setWarning(false);
    }
    const pickupToggle = () => {
        if (["pickupReady"].includes(moreDetailsDelivery.status)) {
            setPickupButton('unready');
        }
        else if (moreDetailsDelivery.status === "submitted") {
            setPickupButton('ready')
        }
        else {
            setPickupButton('');
        }
    }

    const updatePickupStatus = async () => {
        setInProgress(true);
        try {
            console.log('hello')

            if(pickupButton === 'ready') {
                if(moreDetailsDelivery.status === 'submitted') {
                    readyDelivery(moreDetailsDelivery.id, user.apiKey).then(() => {
                        setProgressLabel(prevLabel => prevLabel + 1);
                    })
                }
            }
            else {
                unreadyDelivery(moreDetailsDelivery.id, user.apiKey).then(() => {
                    setProgressLabel(prevLabel => prevLabel + 1);
                })
            }
            if(pickupButton === 'ready') {
                if(moreDetailsDelivery.status === 'submitted') {
                    moreDetailsDelivery.status = 'pickupReady';
                }
            }
            else {
                moreDetailsDelivery.status = 'submitted'
            } 
            await validateAllPickups(user.apiKey);
            closePickupModal();
            pickupToggle();
            setInProgress(false);
            setProgressLabel(0);
            setMoreDetailsUpdate(true);
        }
        catch(err) {
            alert(err)
        }
    }

    const closePickupModal = () => {
        setPickupModal(false);
    }

    const openPickupModal = () => {
        setPickupModal(true);
    }

    /* READYFORPICKUP DISABLE 

    -- BEFORE CURDELLABELBUTTON -- 
                        <div style={{float: 'right', display: 'flex', marginRight: '2vw', marginTop:'0.5vh'}} className="noPointer">
                        {
                        (pickupButton === '') 
                        ?
                            <h5 style={{paddingLeft:'28px', position:'relative', color:'#808080'}}>
                            AVAILABLE FOR PICKUP
                            </h5>
                        :
                            (pickupButton === 'ready')
                            ?
                                <div style={{paddingLeft:'28px', position:'relative', top:'-10px'}}> 
                                <Button data-testid='readyButton' variant='danger' style={{fontSize: '20px', color:'black'}} onClick={openPickupModal}>
                                    AVAILABLE FOR PICKUP
                                </Button>
                                </div>
                            :
                                <div style={{paddingLeft:'28px', position:'relative', top:'-10px'}}> 
                                <Button data-testid='unreadyButton' variant='danger' style={{fontSize: '20px', color:'black'}} onClick={openPickupModal}>
                                    UNDO AVAILABLE FOR PICKUP
                                </Button>
                                </div>
                        }

                        -- AFTER DELETE MODAL -- 

                        <Modal show={pickupModal} onHide={closePickupModal}>
                <Modal.Header closeButton>
                    {inProgress ? `Updating 1 delivery`
                    :
                    `Are you sure you want to update 1 delivery?`
                    }
                </Modal.Header>
                <Modal.Body>
                    { inProgress 
                    ? 
                    <ProgressBar  variant="primary" animated="true" label={`${progressLabel} / ${1}`} now={progressLabel/1 * 100}/>
                    :
                        pickupButton === 'ready'
                        ?
                            moreDetailsDelivery.status === 'submitted'
                            ?
                                <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <strong>{moreDetailsDelivery.recipientName}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <em>
                                            {   (moreDetailsDelivery.addressUnit) 
                                                ? moreDetailsDelivery.addressUnit + ' - ' + moreDetailsDelivery.addressNumber + ' ' + moreDetailsDelivery.addressStreet
                                                : moreDetailsDelivery.addressNumber + ' ' + moreDetailsDelivery.addressStreet
                                            }
                                        </em>
                                    </Col>
                                </Row>
                                </ListGroup.Item>
                            :
                            null             
                        :
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <strong>{moreDetailsDelivery.recipientName}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <em>
                                            {   (moreDetailsDelivery.addressUnit) 
                                                ? moreDetailsDelivery.addressUnit + ' - ' + moreDetailsDelivery.addressNumber + ' ' + moreDetailsDelivery.addressStreet
                                                : moreDetailsDelivery.addressNumber + ' ' + moreDetailsDelivery.addressStreet
                                            }
                                        </em>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        updatePickupStatus();
                    }}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={closePickupModal}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            
                        */


    return (
        <Row className='wrapper'>
            <Card className="CurrentDeliveriesTable">
                {(moreDetailsDelivery) ?
                <div className="MoreDetailsTableHeader">
                    <h4 style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                        Delivery Overview
                    </h4>
                    <hr style={{marginRight: '-1vw'}}/>
                    <h3 style={{paddingLeft: '1vw' }}> {`${moreDetailsDelivery.recipientName}`}</h3>
                    <Row xs={12} style={{display: 'inline', paddingLeft: '1vw'}}>   
                        <Col xs={4} style={{display: 'inline',  float: 'left', paddingLeft: '1vw', paddingTop: '1vh', width: 'vw'}}>
                            <p style={{margin: '0'}}>{`${moreDetailsDelivery.addressNumber} ${moreDetailsDelivery.addressStreet}.,`}</p>
                            <p style={{margin: '0'}}>{`${moreDetailsDelivery.city}, ${moreDetailsDelivery.province}, ${moreDetailsDelivery.country}, ${moreDetailsDelivery.postalCode}`}</p>
                            <div style={{display: 'inline-block'}}>
                                <MoreDetailsMap 
                                    delivery={moreDetailsDelivery}
                                    user={user}
                                />
                            </div>
                        </Col>
                        <Col xs={3}  style={{display: 'inline', float:'left', paddingLeft: '1vw'}}>
                            <p style={{fontSize: '18px', margin: '0'}}>DELIVERY INFO</p>
                            <p style={{margin: '0'}}><b>Tayza ID: </b> {`${moreDetailsDelivery.id}`}</p>
                            <p style={{margin: '0'}}><b>Phone: </b>{`${moreDetailsDelivery.recipientPhone}`}</p>
                            <p style={{margin: '0'}}><b>Zone: </b>{`${moreDetailsDelivery.zone.zone}${moreDetailsDelivery.zone.subzone}`}</p>
                            <p style={{margin: '0'}}><b>Cost: </b>{`$${moreDetailsDelivery.zone.price}`}</p>
                            <p style={{margin: '0'}}><b>Quantity: </b>{`${moreDetailsDelivery.quantity}`}</p>
                            <p style={{margin: '0'}}><b>Gift Sender: </b>{(moreDetailsDelivery.giftSender) ? `${moreDetailsDelivery.giftSender}` : 'N/A'}</p>
                            <p style={{margin: '0'}}><b>Notes: </b>{`${moreDetailsDelivery.deliveryNotes}`}</p>
                        </Col>
                        <Col xs={2}  style={{display: 'inline', float: 'left'}}>
                            <p style={{fontSize: '18px', margin: '0'}}>CURRENT STATUS</p>
                            <p style={{margin: '0'}}><b>Status: </b>{moreDetailsDelivery.status.charAt(0).toUpperCase() + moreDetailsDelivery.status.slice(1)}
                            <CurrentDeliveryStatusIndicator
                                status={moreDetailsDelivery.status}
                            />
                            </p>
                            <p style={{margin: '0'}}><b>Date: </b>{`${moreDetailsDelivery.created.toDateString()}`}</p>
                            <p style={{margin: '0'}}><b>Time: </b>{`${moreDetailsDelivery.created.toLocaleTimeString()}`}</p>

                        </Col>
                        <Col xs={3} style={{display: 'inline', float: 'left'}}>
                            <p style={{fontSize: '18px', margin: '0'}}>STATUS HISTORY</p>
                            {
                            (moreDetailsDelivery) ?
                                (moreDetailsDelivery.statuses.submitted) ?
                                    <>
                                    <p style={{margin: "0"}}><b>Submitted: </b>{(moreDetailsDelivery.statuses.submitted) ? `${new Date(moreDetailsDelivery.statuses.submitted).toDateString()}`: null}</p>
                                    </>
                                :
                                    null
                            :
                            null
                            }
                             {
                            (moreDetailsDelivery) ?
                                (moreDetailsDelivery.statuses.inProgress) ?
                                    <>
                                    <p style={{margin: "0"}}><b>Received: </b>{(moreDetailsDelivery.statuses.inProgress) ? `${new Date(moreDetailsDelivery.statuses.inProgress).toDateString()}`: null}</p>
                                    </>
                                :
                                    null
                            :
                            null
                            }
                            {
                            (moreDetailsDelivery) ?
                                (moreDetailsDelivery.statuses.active) ?
                                    <>
                                    <p style={{margin: "0"}}><b>In Transit: </b>{(moreDetailsDelivery.statuses.active) ? `${new Date(moreDetailsDelivery.statuses.active).toDateString()}`: null}</p>
                                    </>
                                :
                                    null
                            :
                            null
                            }
                            {
                            (moreDetailsDelivery) ?
                                (moreDetailsDelivery.statuses.completed) ?
                                    <>
                                    <p style={{margin: "0"}}><b>Delivered: </b>{(moreDetailsDelivery.statuses.completed) ? `${new Date(moreDetailsDelivery.statuses.completed).toDateString()}`: null}</p>
                                    </>
                                :
                                    null
                            :
                            null
                            }
                            {
                            (moreDetailsDelivery) ?
                                (moreDetailsDelivery.statuses.failed) ?
                                    <>
                                    <p><b>Failed: </b>{(moreDetailsDelivery.statuses.failed) ? `${new Date(moreDetailsDelivery.statuses.failed).toDateString()}`: null}</p>
                                    </>
                                :
                                    null
                            :
                            null
                            }
                        </Col>
                    </Row>
                    <Row xs={12} style={{display: 'inline-block', paddingLeft: '1vw', width: '100%'}} className="driverUpdate">
                        <Col xs={12}>
                            <div>
                                <p style={{fontSize: '18px', margin: '0'}}>DRIVER UPDATE</p>
                                <p style={{margin: '0'}}><b>Driver Notes: </b> {`${(moreDetailsDelivery.completionNotes) ? (moreDetailsDelivery.completionNotes) : ""}`}</p>
                                <p style={{margin: '0'}}><b>Driver Photos: </b></p>
                                {(moreDetailsDelivery.completionPhotos) ? 
                                    moreDetailsDelivery.completionPhotos.map((photo, index) => {
                                        return (
                                            <div style={{width: '20%', height: '30%', float:'left'}}>
                                                <img src={photo} style={{width: '90%'}} className="driverUpdateImages"/>
                                            </div>
                                        )
                                    }) 
                                    :
                                    null
                              }
                            </div>
                        </Col>
                    </Row>
                    {(moreDetailsDelivery.status === "completed") ? 
                    <div style={{ paddingTop: '15px', width: '100%'}} className={(moreDetailsDelivery.completionPhotos) ? "moreDetailsFooter": "moreDetailsFooterNoImage" }>
                        <hr style={{marginRight: '-1vw'}} />
                        <div style={{float: 'right', display: 'flex', marginRight: '2vw', marginTop:'0.5vh'}} className="noPointer">
                            <CurDelLabelButton
                                apiKey={user.apiKey}
                                deliveries={[moreDetailsDelivery]}
                                useLabelButton={user.preferences.useLabelPrinter ? true : false}
                                disabled={false}
                            />
                            <div style={{ paddingLeft: '28px', position: 'relative', top: '-10px' }}>
                                <Button variant='danger' style={{ fontSize: '20px', color: 'black' }} onClick={openWarning}>
                                    DELETE
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ paddingTop: '15px', width: '100%'}} className="moreDetailsFooterNoImage">
                    <hr style={{marginRight: '-1vw'}} />
                    <div style={{float: 'right', display: 'flex', marginRight: '2vw', marginTop:'0.5vh'}} className="noPointer">
                        <CurDelLabelButton
                            apiKey={user.apiKey}
                            deliveries={[moreDetailsDelivery]}
                            useLabelButton={user.preferences.useLabelPrinter ? true : false}
                            disabled={false}
                        />
                        <div style={{ paddingLeft: '28px', position: 'relative', top: '-10px' }}>
                            <Button variant='danger' style={{ fontSize: '20px', color: 'black' }} onClick={openWarning}>
                                DELETE
                            </Button>
                        </div>
                    </div>
                </div>
                    }
                </div>
                :
                null
                }
            </Card>
            <Modal show={warning} onHide={closeWarning}>
                <Modal.Header closeButton>
                    {`Are you sure you want to delete this delivery?`}
                </Modal.Header>
                <Modal.Body>
                    {inProgress ? <ProgressBar variant="primary" animated="true" label={`${1} / ${1}`} now={100} />
                        :
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        <strong>{moreDetailsDelivery.recipientName}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <em>
                                            {(moreDetailsDelivery.addressUnit)
                                                ? moreDetailsDelivery.addressUnit + ' - ' + moreDetailsDelivery.addressNumber + ' ' + moreDetailsDelivery.addressStreet
                                                : moreDetailsDelivery.addressNumber + ' ' + moreDetailsDelivery.addressStreet
                                            }
                                        </em>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        removeDelivery();
                    }}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={closeWarning}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>

    )
};

MoreDetailsOverview.propTypes = {
};


export default MoreDetailsOverview;