import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, InputGroup, FormControl } from 'react-bootstrap';

function CopyApiKey({apiKey}) {
    const [copied, setCopied] = useState(false);
    const copyRef = useRef();

    useEffect(() => {
        if (copied) setTimeout(()=> setCopied(false), 1500)
    }, [copied])

    const copyKey = (event) => {
        copyRef.current.select();
        document.execCommand('copy');
        event.target.focus();
        setCopied(true)
    }

    return (
        <>
            <h6>API Key</h6>
            <InputGroup className="mb-3">
                <FormControl
                    ref={copyRef}
                    value={apiKey}
                    readOnly
                />
                <InputGroup.Append>
                    <Button 
                        onClick={copyKey}
                        disabled={copied}
                    >
                        {(!copied) ? 'Copy' : 'Copied!'}
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </>         
    )
}

CopyApiKey.propTypes = {
    apiKey: PropTypes.string.isRequired
}

export default CopyApiKey