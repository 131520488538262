import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, Spinner} from 'react-bootstrap';
import Axios from 'axios';

function LabelPrinter({deliveries, useLabelPrinter, apiKey, showDialog}) {
    const [loading, setLoading] = useState(false);
    const [printIframe, setPrintIframe] = useState(undefined);

    const getLabels = async () => {
        setLoading(true);
        let ids = deliveries.map(delivery => delivery.id);
        
        const labelsEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/deliveries/labels?ids=${ids}&thermal=${useLabelPrinter}`;
        const headers = {'X-API-Key': apiKey};

        try {
            const result = await Axios.get(labelsEndpoint,{headers, responseType:"blob"});
            console.log(window.URL)
            const url = window.URL.createObjectURL(new Blob([result.data], {type: "application/pdf"}));

            let iframe = printIframe;

            if(!printIframe) {
                iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.style.verticalAlign = 'middle';

                document.body.appendChild(iframe);
                setPrintIframe(iframe);

                iframe.onload = function() {
                    setTimeout(() => {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 100);
                }
            }
            iframe.src = url;
            setLoading(false);
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <>
            <br/>
            <Button 
            href='' 
            block
            onClick={getLabels}
            >
                {
                    (loading)
                    ? 
                    <Spinner       
                        as="span"
                        role="status"
                        animation="border" 
                        variant="secondary"
                    />
                    : <>Print Labels</>
                }
            </Button>
            {
                (showDialog) ? 
                <Row>
                    <Col>
                        <p><em>
                            Please print and attach labels to each of your packages. 
                            This helps our team ensure that each delivery
                            reaches its destination successfully.
                        </em></p>
                    </Col>
                </Row>
                : null
            }

            <Row>
                <Col>
                    <p><em>
                        {
                            (useLabelPrinter) 
                            ? "Currently set to render 4×6 sheets for use with thermal label printers."
                            : "Currently set to render 8.5×11 sheets for use with conventional printers."
                        }
                    </em></p>
                </Col>
            </Row>
        </>
    )
}

LabelPrinter.propTypes = {
    deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
    apiKey: PropTypes.string.isRequired,
    useLabelPrinter: PropTypes.bool,
    showDialog: PropTypes.bool
}

export default LabelPrinter;