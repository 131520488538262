import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Accordion, Card, Button } from 'react-bootstrap';

function ImportedDeliveriesList({deliveries, removeDelivery}) {
    return (
        <Accordion>
            {
                deliveries.map((delivery, index) => {
                    return (
                    <Card key={index}>
                        <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                            {delivery.recipientName}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        {delivery.recipientName} - {delivery.recipientPhone}
                                        {(delivery.notes) ? <><br/>Notes: {delivery.notes}</> : null}
                                        {(delivery.giftSender) ? <><br/>Gift Sender: {delivery.giftSender}</> : null}
                                        <br/>
                                        Boxes: {delivery.quantity}
                                    </Col>
                                    <Col>
                                        { (delivery.addressUnit) ? `${delivery.addressUnit} - ` : ''}
                                        {delivery.addressNumber}&nbsp;
                                        {delivery.addressStreet} 
                                        <br/>
                                        {delivery.city}, {delivery.province}, {delivery.country}
                                        <br/> 
                                        {delivery.postalCode}
                                    </Col>
                                </Row>

                                <Button
                                id={index} 
                                onClick={()=> removeDelivery(index)}
                                align='right'
                                >
                                    Delete
                                </Button>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    )
                })
            }
        </Accordion>
    )
}

ImportedDeliveriesList.propTypes = {
    deliveries: PropTypes.arrayOf(PropTypes.object),
    removeDelivery: PropTypes.func
}

export default ImportedDeliveriesList;