import React, {useState, useEffect} from 'react'; 
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

function DeliveryProgressBar (delivery) {

    const [deliveryProgress, setDeliveryProgress] = useState(0);
    const [deliveryStatus, setDeliveryStatus] = useState("");

    useEffect(() => {
        refreshStatusUpdate();
    }, [delivery])

    const refreshStatusUpdate = () => {
        console.log(delivery.delivery)
        if(Object.keys(delivery.delivery).length != 0){
            let status;
            if (delivery.delivery.status.failed || delivery.delivery.status.completed) {
                if (delivery.delivery.status.failed) { 
                    status = 'failed'; 
                }
                if (delivery.delivery.status.completed) { 
                    status = 'completed'; 
                }
            }
            else if (delivery.delivery.status.inProgress) { 
                status = 'inProgress'; 
            }
            else if (delivery.delivery.status.active) {
                let activeTime = new Date(delivery.delivery.status.active)
                let submittedTime = new Date(delivery.delivery.status.submitted)
                status = ( activeTime > submittedTime ) 
                ?  'active' 
                :  'submitted';
            }      
            else if (delivery.delivery.status.submitted) { 
                status = 'submitted';
            }
    
            let progress;
            switch(status){
                case 'failed':
                    progress = 100;
                    break;
                case 'completed':
                    progress = 100;
                    break;
                case 'inProgress':
                    progress = 67; 
                    break;
                case 'active':
                    progress = 34;
                    break;
                default:
                    progress = 0;
            }

            setDeliveryStatus(status);
            setDeliveryProgress(progress);
        }
    }

    return(
        <React.Fragment>
            <div style={{width: '80%'}}>
                <ProgressBar
                    percent={deliveryProgress}
                    filledBackground={deliveryStatus === "failed" ? "#ec2400" : "linear-gradient(to right, #f9e25a, #f0bb31)"}
                >
                    <Step transition="scale" position={0}>
                    {({accomplished}) => (
                        <div
                            className={accomplished ? "filledStep" : "emptyStep"}
                        >
                            &nbsp;
                        </div>
                    )}
                    </Step>
                    <Step transition="scale" position={0}>
                    {({accomplished}) => (
                        <div
                            className={accomplished ? "filledStep" : "emptyStep"}
                        >
                            &nbsp;
                        </div>
                    )}
                    </Step>
                    <Step transition="scale" position={0}>
                    {({accomplished}) => (
                        <div
                            className={accomplished ? "filledStep" : "emptyStep"}
                        >
                            &nbsp;
                        </div>
                    )}
                    </Step>
                    <Step transition="scale" position={0}>
                    {({accomplished}) => (
                        <div
                            className={accomplished ? "filledStep" : "emptyStep"}
                        >
                            &nbsp;
                        </div>
                    )}
                    </Step>
                </ProgressBar>
            </div>
        </React.Fragment>
    );
}

export default DeliveryProgressBar;