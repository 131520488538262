import { Form, Row, Col, Button} from "react-bootstrap";
import React, {useState, useEffect} from 'react'; 

function DeliveryTrackingSearch (deliveryId) {

    const[nextDeliveryId, setNextDeliveryId] = useState(deliveryId);

    const goToNextTrackingLink = () => {
        window.open(`/tracking/${nextDeliveryId}`, "_self")
    }

    return(
        <React.Fragment>
            <Row>
                <Col style={{paddingRight:'2px'}}>
                    <Form.Group controlId="nextDeliveryId" onChange={event=>setNextDeliveryId(event.target.value)}>
                        <Form.Control className="noShadowFocus" type="text" placeholder={"DELIVERY ID"} style={{
                            backgroundColor:"whitesmoke",
                            height: "3em",
                            borderRadius: "0%",
                            border: '0px'
                        }}/>
                    </Form.Group>
                </Col>
                <Col style={{paddingLeft: '0px'}}>
                    <Button 
                            onClick={goToNextTrackingLink}
                            style={{
                                height: "3em",
                                backgroundColor: '#ffb63e',
                                color: '#171e66',
                                fontWeight: 'bold',
                                borderRadius: "0%",
                                border: '0px'
                            }}
                    >
                            TRACK
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DeliveryTrackingSearch;