import React, { useEffect, useState } from "react";
import Day from './CurrentDeliveriesDay.component'
import PropTypes from 'prop-types';

const Days = ({dateProp, SelectedDateUpdate, prevMonth, nextMonth, selectedDate}) => {
    const [daysArray, setDaysArray] = useState([])
    const [firstDay, setFirstDay] = useState(0)
    const [prevMonthDays, setPrevMonthDays] = useState(0)

    useEffect(() => {
        let tempfirstDay = new Date(dateProp.getFullYear(),dateProp.getMonth(), 1).getDay()
        tempfirstDay = (tempfirstDay+6)%7
        setFirstDay(tempfirstDay);
        if(dateProp.getMonth() === 1 || dateProp.getMonth() === 2)fillCalendar()
        setPrevMonthDays(new Date(dateProp.getFullYear(), dateProp.getMonth(), 0).getDate())
       
    }, [dateProp])

    useEffect(() => {
        fillCalendar()
    }, [firstDay, prevMonthDays])

    const fillCalendar = () => {
        const numOfDays = new Date(dateProp.getFullYear(), dateProp.getMonth() + 1, 0).getDate()
        let validDays = new Array(numOfDays); for (let i=0; i<numOfDays; ++i) validDays[i] = 0;     
        for(let i=0; i<firstDay; i++)validDays.unshift(1);
        const remainingDays = (Math.ceil((numOfDays + firstDay)/7)*7) - (numOfDays+firstDay);
        for(let i=0; i<remainingDays; i++)validDays.push(2)
        setDaysArray(validDays);
    }

    const dayValue = (element, index) => {
        const numOfDays = new Date(dateProp.getFullYear(), dateProp.getMonth() + 1, 0).getDate()
        let tempDate = new Date(dateProp)
        switch(element) {
            case 0: //current Month
                tempDate.setDate(index-firstDay + 1)
                return tempDate;
            case 1: //Previous Month
                tempDate.setMonth(dateProp.getMonth()-1)
                tempDate.setDate(prevMonthDays-firstDay+index+1)
                return tempDate
            case 2: //Next Month
                tempDate.setMonth(dateProp.getMonth() + 1)
                tempDate.setDate(index - (firstDay+numOfDays) + 1)
                return tempDate
        }
        
    }

    const createDay = (element, index) => {
        return <Day 
                    currentMonth = {element}
                    val = {dayValue(element, index)}
                    key = {index} SelectedDateUpdate = {SelectedDateUpdate}
                    prevMonth = {prevMonth} nextMonth = {nextMonth} 
                    selectedDate = {selectedDate} dateProp = {dateProp}
                />
    }
     
    return (
        <div>
            <ul className = "weekdays">
                <li>MON</li>
                <li>TUE</li>
                <li>WED</li>
                <li>THU</li>
                <li>FRI</li>
                <li>SAT</li>
                <li>SUN</li>
            
            </ul>
            <div className = "days">
                {
                    daysArray.map( (element, index)=> {
                        return createDay(element, index)
                    })
                }
            </div>
        </div>
    )
}

Days.propTypes = {
    dateProp: PropTypes.instanceOf(Date).isRequired,
    SelectedDateUpdate: PropTypes.func.isRequired,
    prevMonth: PropTypes.func.isRequired,
    nextMonth: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired
}

export default Days