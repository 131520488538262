import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Button } from 'react-bootstrap';

import EditCompanyName from './EditCompanyName.component';
import EditPickupAddress from './EditPickupAddresses.component';
import EditPassword from './EditPassword.component';
import PickupDays from './PickupDays.component';

function ProfileModal ({children, user, refreshUser}) {
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <div data-testid="modalController" onClick={() => setShowModal(true)}>{children}</div>
            <Modal
                data-testid="profileModal"
                show={showModal} 
                onHide={() => setShowModal(false)} 
                size='md'
            >
                <Modal.Header closeButton>
                    <Row>
                        <Col xs="auto">
                            <Modal.Title>Profile</Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                        <EditCompanyName 
                            companyName={user.companyName}
                            refreshUser={refreshUser}
                        />
                        <hr/>
                        <EditPickupAddress 
                            addresses={user.address}
                            refreshUser={refreshUser}
                        />                            
                        <hr/>
                        <EditPassword/> 
                        
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                            variant="primary" 
                            onClick={() => setShowModal(false)}
                        >
                            Done
                        </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

ProfileModal.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    user: PropTypes.object.isRequired, 
    refreshUser: PropTypes.func.isRequired
}

export default ProfileModal