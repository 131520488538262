import React from 'react'; 
import { Navbar } from 'react-bootstrap';
import Logo from '../../../assets/logo.png';

function MoreDetailsHeader({setShowMoreDetails}) {
    return (
        <Navbar fixed="top" bg="primary" variant="dark" expand="lg">
            <Navbar.Brand href="/">
                <img
                    src={Logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Tayza T Logo"
                /> 
                <em>Current Deliveries</em>
                <span  style={{paddingLeft: '2%'}} className="CurrentDeliveriesReturn"> <b>{` < Dashboard`}</b></span>
            </Navbar.Brand>
            <Navbar.Brand href="/current-deliveries" onClick={() => {setShowMoreDetails(false)}}>
                <span><b>{` < All Deliveries`}</b></span>
            </Navbar.Brand>
        </Navbar>
    );
};

export default MoreDetailsHeader;