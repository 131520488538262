import React from 'react';
import PropTypes from 'prop-types';

function SidebarIcon ({icon}) {
    return (
        <>
        {
            (icon) ? 
                <img 
                    src={icon.img} 
                    alt={(icon.alt)? icon.alt : ""} 
                    width='20vh'
                />
            : null
        }
        </>
    );
}

SidebarIcon.propTypes = {
    icon: PropTypes.object
}

export default SidebarIcon;