import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form, FormCheck} from 'react-bootstrap';

import doChangeUserInfo from '../../services/doChangeUserInfo.service';

function SelectPrintMethod ({preferences, refreshUser}) {
    const [useLabelPrinter, setUseLabelPrinter] = useState((preferences && preferences.useLabelPrinter));

    useEffect(() => {
        setUseLabelPrinter((preferences && preferences.useLabelPrinter));
    }, [preferences]);

    const toggleLabelPrinter = (event) => {
        console.log('here')
        doChangeUserInfo({'preferences.useLabelPrinter': !useLabelPrinter})
        .then(refreshUser);
    }

    return (
        <>
            <h6>Use Label Printer</h6>
            <Form>
                <Row>
                    <Col>
                        <FormCheck
                            type='switch'
                            id='print-method'
                            label={
                                (useLabelPrinter)
                                ? (<em>Labels will be rendered on 4×6 sheets for use with thermal label printers</em>)
                                : (<em>Labels will be rendered on 8.5×11 sheets for use with conventional printers</em>)
                            }
                            checked={useLabelPrinter}
                            onChange={toggleLabelPrinter}
                        />  
                    </Col>
                </Row>
            </Form>
        </>         
    )
}

SelectPrintMethod.propTypes = {
    preferences: PropTypes.object,
    refreshUser: PropTypes.func.isRequired
}
export default SelectPrintMethod