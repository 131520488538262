import React from 'react';
import PropTypes from 'prop-types';

import Deliveries from './Deliveries.component';
import HistoryButton from './HistoryButton.component';
import SidebarHeader from './SidebarHeader.component';

function Sidebar({user, deliveries, refreshDeliveries}) {
    return (
        <nav className="Sidebar">
            <SidebarHeader companyName={(user) ? user.companyName : null}/>
            <Deliveries
                deliveries={deliveries}
                refreshDeliveries={refreshDeliveries}
                user={user}
            />
            <HistoryButton/>
        </nav>
    )
}

Sidebar.propTypes = {
    user: PropTypes.object,
    deliveries: PropTypes.arrayOf(PropTypes.object),
    refreshDeliveries: PropTypes.func
}

export default Sidebar;