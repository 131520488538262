import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import doDeliveryExport from '../../services/doDeliveryExport.service';

function ExportButton({ apiKey }) {
    return (
        <Button 
            variant='secondary'
            onClick={()=> doDeliveryExport(apiKey)}
            disabled={!apiKey}
        >
            Download as CSV
        </Button>
    );
};

ExportButton.propTypes = {
    deliveries: PropTypes.string.isRequired
};

export default ExportButton;