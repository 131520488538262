import React, {useState, useEffect} from 'react'; 
import {Row, Col, Card} from 'react-bootstrap'

import fullLogo from '../../assets/logo-full.png'
import getDeliveryTracking from '../../services/getDeliveryTracking.service';

import DeliveryTrackingSearch from './DeliveryTrackingSearch.component.js'
import DeliveryStatusUpdate from './DeliveryStatusUpdate.component.js'
import DeliveryProgressBar from './DeliveryProgressBar.component.js'
import DeliveryMoreInfo from './DeliveryMoreInfo.component.js'

function DeliveryTracking ({deliveryId}) {

    const [delivery, setDelivery] = useState({});    
    const supportNumber = "647-568-2992"

    useEffect(() => {
        refreshDelivery();
    }, [])

    const refreshDelivery = async () => {
        const delivery = await getDeliveryTracking(deliveryId);
        console.log(delivery);
        setDelivery(delivery);
    }

        return (
            <React.Fragment>
                <Row>
                    <div className="trackingBar">
                        <div className="trackingTopLogo">
                            <a href="/login">
                                <img
                                    src={fullLogo}
                                    width="222px"
                                    height="50px"
                                    alt="Tayza Logo"
                                />
                            </a>
                        </div>
                    </div>
                </Row>
                <div style={{textAlign: 'center', paddingTop: '6em'}}>
                    <p style={{fontSize: '10px'}} className='text-muted'>
                        Contact Support: {supportNumber}
                    </p>
                    <p style={{fontSize: '12px'}} className='text-muted'>
                        Enter a Tayza Delivery ID for tracking
                    </p>
                </div>
                <Row style={{ display: 'inline', float: 'left', width: '120%', paddingLeft: '23%'}}>
                    <DeliveryTrackingSearch deliveryId={deliveryId}></DeliveryTrackingSearch>
                </Row>
                {!delivery 
                ? 
                    deliveryId != "staticRedirect" 
                    ?
                        <h1 className="notFoundTracking">We are unable to find a delivery with this ID. Please try again.</h1>
                    :
                        null
                :
                <React.Fragment>
                    <Row style={{ display: 'inline', float: 'left', width: '100%', paddingBottom: '2%'}}>
                        <DeliveryStatusUpdate delivery={delivery ? delivery : null} deliveryId={deliveryId}></DeliveryStatusUpdate>
                    </Row>
                    <Row style={{ display: 'inline', float: 'left', width: '100%', paddingLeft: '17%', paddingBottom: '5%'}}>
                        <DeliveryProgressBar delivery={delivery ? delivery : null}></DeliveryProgressBar>
                    </Row>
                    <Row style={{ display: 'inline', float: 'left', width: '78%', paddingLeft: '23%'}}>
                        <DeliveryMoreInfo delivery={delivery ? delivery : null}></DeliveryMoreInfo>
                    </Row>
                </React.Fragment>
                }
            </React.Fragment>
        )
}

export default DeliveryTracking;