import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import StartDateTimePicker from './date-time-filter/CurrentDeliveriesStartDateTimePicker.component';
import EndDateTimePicker from './date-time-filter/CurrentDeliveriesEndDateTimePicker.component';
import fetchZones from '../../services/fetchZones.service';


function CurrentDeliveriesFilter(
    { user, selectedStatus, setSelectedStatus, selectedZones, setSelectedZones, selectedPickUp, setSelectedPickUp,
        selectedGiftSenders, setSelectedGiftSenders, setStartDate, setEndDate, giftSenders }
) {

    const [toggleEndDateTime, setToggleEndDateTime] = useState(false);
    const [toggleStartDateTime, setToggleStartDateTime] = useState(false);
    const [zones, setZones] = useState(null);


    useEffect(() => {
        if (!zones) {
            refreshZones();
        }
    }, [zones])

    useEffect(() => {
        if (toggleStartDateTime) {
            setToggleEndDateTime(false)
        }
    }, [toggleStartDateTime])

    useEffect(() => {
        if (toggleEndDateTime) {
            setToggleStartDateTime(false)
        }
    }, [toggleEndDateTime])

    const refreshZones = async () => {
        const zonesList = await fetchZones();
        setZones(zonesList)
    }

    const handleStatus = (status) => {
        if (selectedStatus.includes(status)) {
            setSelectedStatus(prev => prev.filter(item => item !== status));
        }
        else {
            setSelectedStatus(prev => prev.concat(status))
        }
    }

    const handleZone = (zone) => {
        if (selectedZones.includes(zone)) {
            setSelectedZones(prev => prev.filter(item => item !== zone));
        }
        else {
            setSelectedZones(prev => prev.concat(zone));
        }
    }

    const handlePickUp = (pickUpAddress) => {
        if (selectedPickUp.includes(pickUpAddress)) {
            setSelectedPickUp(prev => prev.filter(item => item !== pickUpAddress));
        }
        else {
            setSelectedPickUp(prev => prev.concat(pickUpAddress));
        }
    }

    const handleGiftSenders = (giftSender) => {
        if (selectedGiftSenders.includes(giftSender)) {
            setSelectedGiftSenders(prev => prev.filter(item => item !== giftSender));
        }
        else {
            setSelectedGiftSenders(prev => prev.concat(giftSender));
        }
    }

    return (
        <Row className='wrapper'>
            <Card className="CurrentDeliveriesFilter">
                <h3 style={{ paddingLeft: '20px', paddingTop: '20px', marginBottom: '-25px' }}>
                    Filters
                    <hr style={{ margin: '17px -20px' }} />
                </h3>
                <Card.Body style={{ display: 'inline' }}>
                    <h5 style={{ fontSize: '1.1vw' }}>
                        DATE AND TIME
                    </h5>
                    <div style={{ display: 'flex', paddingLeft: '10px', marginRight:'1vw', fontSize: '0.8vw' }}>
                        <label id='startLabel'>START:</label>
                        <StartDateTimePicker
                            setStartDate={setStartDate}
                            toggleStartDateTime={toggleStartDateTime}
                            setToggleStartDateTime={setToggleStartDateTime}
                        />
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '10px', marginRight:'1vw', fontSize: '0.8vw' }}>
                        <label id='endLabel'>END:</label>
                        <EndDateTimePicker
                            setEndDate={setEndDate}
                            toggleEndDateTime={toggleEndDateTime}
                            setToggleEndDateTime={setToggleEndDateTime}
                        />
                    </div>
                    <h5 style={{ fontSize: '1.1vw'}}>
                        STATUS
                    </h5>
                    <Row style={{ display: 'flex', paddingLeft: '10px', fontSize: '0.8vh' }}>
                        <Col xs={5}>
                            <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label="Submitted" onClick={() => { handleStatus("submitted") }} />
                        </Col>
                        <Col xs={5}>
                            <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label="Delivered" onClick={() => { handleStatus("completed") }} />
                        </Col>
                        <Col xs={5}>
                            <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label="Awaiting Pickup" onClick={() => { handleStatus("active") }} />
                        </Col>
                        <Col xs={5}>
                            <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label="Failed" onClick={() => { handleStatus("failed") }} />
                        </Col>
                        <Col xs={5}>
                            <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label="In Transit" onClick={() => { handleStatus("inProgress") }} />
                        </Col>
                    </Row>
                    <br />
                    <h5 style={{ marginTop: '-10px', fontSize: '1.1vw' }}>
                        ZONE
                    </h5>
                    <Row style={{ display: 'flex', paddingLeft: '10px', marginRight: '-100px' }}>
                        {
                            (zones)
                                ?
                                zones.map((zone) => {
                                    return (
                                        <Col xs={5}>
                                            <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label={zone.title} onClick={() => { handleZone(`${zone.zone}${zone.subzone}`) }} />
                                        </Col>
                                    )
                                })
                                :
                                null
                        }
                    </Row>
                    <br />
                    <h5 style={{ marginTop: '-10px', fontSize: '1.1vw' }}>
                        PICKUP ADDRESS
                    </h5>
                    <div className="FilterBox">
                        {
                            (user) ? user.address.map((address) => {
                                return (
                                    <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label={`${address.number} ${address.street}`} onClick={() => { handlePickUp(`${address.number} ${address.street}`) }} />
                                )
                            })
                                :
                                null
                        }
                    </div>
                    <br />
                    <h5 style={{ fontSize: '1.1vw' }}>
                        GIFT SENDER
                    </h5>
                    <div className="FilterBox">
                        {
                            (giftSenders) ? giftSenders.map((giftSender) => {
                                if (giftSender) {
                                    return (
                                        <Form.Check style={{ fontSize: '0.8vw' }} type="checkbox" label={`${giftSender}`} onClick={() => { handleGiftSenders(giftSender) }} />
                                    )
                                }
                            })
                                :
                                null
                        }
                    </div>
                </Card.Body>
            </Card>
        </Row>
    )
};

CurrentDeliveriesFilter.propTypes = {
    user: PropTypes.object,
    selectedStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedStatus: PropTypes.func.isRequired,
    selectedZones: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedZones: PropTypes.func.isRequired,
    selectedPickUp: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedPickUp: PropTypes.func.isRequired,
    giftSenders: PropTypes.arrayOf(PropTypes.string).isRequired,
    setGiftSenders: PropTypes.func.isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired
};

export default CurrentDeliveriesFilter;