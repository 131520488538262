import axios from 'axios';

const validatePickup = async (apiKey) => {

    const deliveriesEndpoint = process.env.REACT_APP_DELIVERIES_ENDPOINT
    const headers = {
        'X-API-Key': apiKey
    }
    try {
        await axios.post(
            `${deliveriesEndpoint}/deliveries/validate`, 
            {},
            {headers:headers}
        )
        return true;

    } catch (err) {
        console.log(err);
        return false;
    }
}

export default validatePickup;