import React, { useState, useEffect } from 'react';

import DeliveryList from './DeliveryList.component';
import ExportButton from './ExportButton.component';
import HistoryPageHeader from './HistoryPageHeader.component';
import HistoryPageFooter from './HistoryPageFooter.component';
import Layout from '../_common/Layout.component';
import Pagination from './Pagination.component';
import ReturnButton from './ReturnButton.component';

import fetchUserInfo from '../../services/fetchUserInfo.service';
import fetchDeliveryHistory from '../../services/fetchDeliveryHistory.service';

function History() {
    const [user, setUser] = useState(null);
    const [deliveries, setDeliveries] = useState(null);
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        if (!user) refreshUser();
        refreshDeliveries();
    }, [user, page]);

    const refreshDeliveries = async (startDate, endDate) => {
        if (user) {
            try {
               const result = await fetchDeliveryHistory(user.apiKey, page, 30, startDate, endDate);
               setDeliveries(result.deliveries);
               setLastPage(result.numPages - 1);
            } 
            catch(err) {
                console.log(err);
            }
        }
    }

    const refreshUser = async () => {
        const user = await fetchUserInfo();
        setUser(user);
    }

    return (
        <Layout>
            <HistoryPageHeader
                refreshDeliveries={refreshDeliveries}
            />
            <DeliveryList                     
                deliveries={deliveries}
                refreshDeliveries={refreshDeliveries}
            />
            <HistoryPageFooter>
                <ReturnButton/>
                <Pagination 
                    page={page}
                    lastPage={lastPage}
                    setPage={setPage}
                />
                <ExportButton apiKey= {(user) ? user.apiKey : null}/>
            </HistoryPageFooter>
        </Layout>
    )
}

export default History;