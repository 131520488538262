import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, FormCheck, Button } from 'react-bootstrap';


function ImportManually({user, addDeliveries}) {
    const defaultDelivery = {
        recipientName: '',
        recipientPhone: '',
        addressNumber: '',
        addressStreet: '',
        addressUnit:'',
        city:'',
        province:"Ontario",
        country: "Canada",
        postalCode:'',
        quantity:'1',
        notes:'',
        pickupIndex: null,
        giftSender: null,
        length: '',
        width: '', 
        height: '', 
        weight: '',
        useSmartServe: user.preferences.useSmartServe
    };

    const [newDelivery, setNewDelivery] = useState(defaultDelivery);
    const [useDefaultAddress, setUseDefaultAddress] = useState(true);
    const [isGift, setIsGift] = useState(false);
    const [streetAddress, setStreetAddress] = useState('');
    const [useSmartServe, setUseSmartServe] = useState(user.preferences.useSmartServe);

    const isValidDelivery = () => {
        const recipientFilled = (
            !!newDelivery.recipientName
        );
        const addressFilled = (
            !!newDelivery.addressNumber && 
            !!newDelivery.addressStreet && 
            !!newDelivery.city && 
            !!newDelivery.province && 
            !!newDelivery.country && 
            !!newDelivery.postalCode
        );
        const optionsFilled = (
            !!newDelivery.quantity
        );

        return (recipientFilled && addressFilled && optionsFilled);
    }

    const handleUpdate = (key, value) => {
        if(key==="streetAddress") {
            setStreetAddress(value);
            
            let split = value.trim().split(" ");
            let street = '';
            let number = split.shift();

            if (split[0]) {
                street = split.reduce((prev, current) => {  
                    return prev.concat(' ', current)
                });
            }

            const nextDelivery = {
                ...newDelivery, 
                addressNumber: number, 
                addressStreet: street 
            };

            setNewDelivery(nextDelivery);
        }
        
        else setNewDelivery({...newDelivery, [key]: value});
    }

    const handleSave = event => {
        event.preventDefault();
        addDeliveries([newDelivery]);
        handleClear();
    }

    const handleClear = () => {
        setNewDelivery(defaultDelivery);
        setStreetAddress('');
        setIsGift(false);
        setUseSmartServe(user.preferences.useSmartServe);
    }

    /* PACKAGE DIMENSIONS CONTROLS

                <Row>
                <Col>
                    <h5>Package Dimensions (optional)</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group 
                        controlId="length" 
                        onChange={event=>handleUpdate("length", event.target.value)}
                    >
                        <Form.Label>Length (m)</Form.Label>
                        <Form.Control type="text" value={newDelivery.length}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="width" 
                        onChange={event=>handleUpdate("width", event.target.value)}
                    >
                        <Form.Label>Width (m)</Form.Label>
                        <Form.Control type="text" value={newDelivery.width}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="height" 
                        onChange={event=>handleUpdate("height", event.target.value)}
                    >
                        <Form.Label>Height (m)</Form.Label>
                        <Form.Control type="text" value={newDelivery.height}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="weight" 
                        onChange={event=>handleUpdate("weight", event.target.value)}
                    >
                        <Form.Label>Weight (kg)</Form.Label>
                        <Form.Control type="text" value={newDelivery.weight}/>
                    </Form.Group>
                </Col>
            </Row>

    */

    return(
        <Form 
        onSubmit={handleSave}
        onReset={handleClear}
        >
            <Row>
                <Col>
                    <Form.Group 
                        controlId="recipientName" 
                        onChange={event=>handleUpdate("recipientName", event.target.value)}
                    >
                        <Form.Label>Recipient Name</Form.Label>
                        <Form.Control type="text" value={newDelivery.recipientName}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="recipientPhone"
                        onChange={event=>handleUpdate("recipientPhone", event.target.value)}
                    >
                        <Form.Label>Recipient Phone</Form.Label>
                        <Form.Control type="text" value={newDelivery.recipientPhone}/>                                                       
                    </Form.Group>
                </Col>
            </Row>            
            <hr/>
            <Row>
                <Col xs='9'>
                    <Form.Group 
                        controlId="streetAddress" 
                        onChange={event=>handleUpdate("streetAddress", event.target.value)}
                    >
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control type="text" value={streetAddress}/>
                    </Form.Group>
                </Col>
                <Col xs='3'>
                    <Form.Group 
                        controlId="addressUnit" 
                        onChange={event=>handleUpdate("addressUnit", event.target.value)}
                    >
                        <Form.Label>Apt. / Unit</Form.Label>
                        <Form.Control type="text" value={newDelivery.addressUnit}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group 
                        controlId="city" 
                        onChange={event=>handleUpdate("city", event.target.value)}
                    >
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" value={newDelivery.city}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="province" 
                        onChange={event=>handleUpdate("province", event.target.value)}
                    >
                        <Form.Label>Province</Form.Label>
                        <Form.Control as="select" custom readOnly>
                            <option>Ontario</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="country" 
                        onChange={event=>handleUpdate("country", event.target.value)}
                    >
                        <Form.Label>Country</Form.Label>
                        <Form.Control type="text" value={newDelivery.country} readOnly/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group 
                        controlId="postalCode" 
                        onChange={event=>handleUpdate("postalCode", event.target.value)}
                    >
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control type="text" value={newDelivery.postalCode}/>
                    </Form.Group>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <Form.Group 
                        controlId="notes" 
                        onChange={event=>handleUpdate("notes", event.target.value)}
                    >
                        <Form.Label>Delivery Notes</Form.Label>
                        <Form.Control as="textarea" value={newDelivery.notes}/>                                                       
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs='3'>
                    <Form.Group 
                        controlId="quantity" 
                        onChange={event=>handleUpdate("quantity", event.target.value)}
                    >
                        <Form.Label>Number of Boxes</Form.Label>
                        <Form.Control as="input" type='number' min='1' value={newDelivery.quantity}/>                                                       
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id='default-address'
                        label='Use default pickup address'
                        checked={useDefaultAddress}
                        onChange={()=>{
                            setUseDefaultAddress(!useDefaultAddress); 
                            handleUpdate('pickupIndex', useDefaultAddress ? null : 1);
                        }}
                    />                                                      
                </Col>
            </Row>
            <Row hidden={useDefaultAddress}>
                <Col xs='4'>
                    <Form.Group 
                        controlId="pickupIndex" 
                        onChange={event=>handleUpdate("pickupIndex", event.target.value)}
                    >
                        <Form.Control 
                            as="select"
                            custom
                            onClick={event=>handleUpdate("pickupIndex", event.target.value)}
                        >
                            {
                            (user && user.address.length > 0) 
                            ? 
                                user.address.map((address, index) => (
                                    <option value={index}>
                                            {address.number} {address.street}
                                    </option>
                                ))
                            : 
                                <option readOnly>No addresses found</option>
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id='is-gift'
                        label='Send as a gift'
                        checked={isGift}
                        onChange={()=>{
                            setIsGift(!isGift); 
                            handleUpdate('giftSender', "");
                        }}
                    />                                                      
                </Col>
            </Row>
            <Row hidden={!isGift}>
                <Col xs='4'>
                    <Form.Group 
                        controlId="giftSender" 
                        onChange={event=>handleUpdate("giftSender", event.target.value)}
                    >
                        <Form.Label>Gift Sender</Form.Label>
                        <Form.Control value={newDelivery.giftSender}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                <Form.Check
                     type="checkbox"
                     id='default-smartserve'
                     custom
                     label='Smart Serve'
                     checked={useSmartServe}
                     onClick={()=>{
                        handleUpdate('useSmartServe', !useSmartServe);
                        setUseSmartServe(!useSmartServe); 
                     }}
                />                                                      
            </Col>
            </Row>
            <Row>
                <Col align='right'>
                        <Button 
                            size='sm'
                            type='reset'
                            variant='primary'
                        >
                            Clear
                        </Button>
                        &nbsp;
                        <Button 
                            size='sm'
                            type='submit' 
                            variant='secondary'
                            disabled={!isValidDelivery()}
                        >
                            Save
                        </Button>
                </Col>
            </Row>                                    
        </Form>
    )
}

ImportManually.propTypes = {
    user: PropTypes.object,
    addDeliveries: PropTypes.func.isRequired
}

export default ImportManually;