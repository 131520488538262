import axios from 'axios';

const deleteDelivery = async (deliveryId, apiKey) => {

    const deliveriesEndpoint = process.env.REACT_APP_DELIVERIES_ENDPOINT
    const headers = {
        'X-API-Key': apiKey
    }
    try {
        await axios.delete(
            `${deliveriesEndpoint}/deliveries/${deliveryId}`, 
            {headers:headers}
        )

        return true;

    } catch (err) {
        console.log(err);
        return false;
    }
}

export default deleteDelivery;