import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button, Spinner} from 'react-bootstrap';
import Axios from 'axios';

function LabelButton({deliveries, useLabelButton, apiKey}) {
    const [loading, setLoading] = useState(false);
    const [printIframe, setPrintIframe] = useState(undefined);

    const getLabels = async () => {
        setLoading(true);
        let ids = deliveries.map(delivery => delivery.id);
        
        const labelsEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/deliveries/labels?ids=${ids}&thermal=${useLabelButton}`;
        const headers = {'X-API-Key': apiKey};

        try {
            const result = await Axios.get(labelsEndpoint,{headers, responseType:"blob"});
            console.log(window.URL)
            const url = window.URL.createObjectURL(new Blob([result.data], {type: "application/pdf"}));

            let iframe = printIframe;

            if(!printIframe) {
                iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.style.verticalAlign = 'middle';

                document.body.appendChild(iframe);
                setPrintIframe(iframe);

                iframe.onload = function() {
                    setTimeout(() => {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 100);
                }
            }
            iframe.src = url;
            setLoading(false);
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <>
            <br/>
            <Button 
                href=''
                style={{width: '40%'}}
                variant="secondary" 
                onClick={getLabels}
            >
                {
                    (loading)
                    ?
                    <>
                        <Spinner
                            as="span"
                            role="status"
                            animation="border" 
                            variant="primary"
                            size="sm"
                        />
                    </>
                    : <>Labels</>
                }
            </Button>
        </>
    )
}

LabelButton.propTypes = {
    deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
    apiKey: PropTypes.string.isRequired,
    useLabelButton: PropTypes.bool,
}

export default LabelButton;