import React, {useState, useEffect} from 'react'; 
import PropTypes from 'prop-types';

import Layout from '../_common/Layout.component';
import SettingsMenu from './SettingsMenu.component';
import Sidebar from './sidebar/Sidebar.component';
import Map from './map/Map.component'
import fetchUserInfo from '../../services/fetchUserInfo.service';
import fetchDeliveries from '../../services/fetchDeliveries.service';

function Home({checkIsLoggedIn}) {
    const [user, setUser] = useState(null);
    const [deliveries, setDeliveries] = useState(null);

    const refreshUser = async () => {
        const prevUser = user ? {...user} : null;
        const nextUser = await fetchUserInfo();
        
        if (!nextUser && prevUser) {
            alert("Your session has expired due to inactivity");
            checkIsLoggedIn();
        } else setUser(nextUser);
    }

    const refreshDeliveries = async () => {
        if (user) {
            const nextDeliveries = await fetchDeliveries(user.apiKey, true);
            const nextDeliveriesSorted = sortDeliveries(nextDeliveries);
            setDeliveries(nextDeliveriesSorted);
        }
    }

    const sortDeliveries = (deliveries) => {
        if(deliveries) {
            return deliveries.sort((a, b) => {

                //1: If deliveries have different fulfillment statuses, they are sorted by status
                const statusesPriority = ['failed', 'completed', 'submitted', 'active', 'inProgress']

                const aStatusNum = statusesPriority.findIndex(val => val === a.status);
                const bStatusNum = statusesPriority.findIndex(val => val === b.status);

                if(aStatusNum !== bStatusNum) return (bStatusNum - aStatusNum);

                //2: If deliveries have same fulfillment status, sort by time status updated
                else if (a.arrivalTime && b.arrivalTime) return (b.statusTime - a.statusTime);

                else return 1;
            })
        }
        else return null

    }
    
    useEffect(() => {
        refreshUser().then(refreshDeliveries);
        setInterval(() => {refreshUser().then(refreshDeliveries)}, 1000*90);
    }, [JSON.stringify(user)]);

    return (
        <Layout>
            <Sidebar 
                user={user}
                refreshUser={refreshUser}
                deliveries={deliveries}
                refreshDeliveries={refreshDeliveries}
            />
            <Map
                deliveries={deliveries}
                user={user}
                refreshDeliveries={refreshDeliveries}
            />
            <SettingsMenu 
                checkIsLoggedIn={checkIsLoggedIn}
                user={user}
                refreshUser={refreshUser}
            />
        </Layout>
    )
}

Home.propTypes = {
    checkIsLoggedIn: PropTypes.func.isRequired
}

export default Home;