import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, Button } from 'react-bootstrap';

import ImportManually from './ImportManually.component';
import ImportBySpreadsheet from './ImportBySpreadsheet.component';
import ImportedDeliveriesList from './ImportedDeliveriesList.component';

function ImportDeliveries({user, nextDeliveries, setNextDeliveries}) {
    const [spreadsheetImport, setSpreadsheetImport] = useState(false);
    
    const addDeliveries = deliveries => {
        setNextDeliveries([...nextDeliveries, ...deliveries]);
    }

    const removeDelivery = index => {
        let deliveries = [...nextDeliveries];
        deliveries.splice(index, 1);
        setNextDeliveries(deliveries);
    }

    return(
        <>
            <Row>
                <Col>
                    <Card key='0'>
                        <Card.Header>
                            <Row>
                                <Col xs='auto'>
                                    <h5>{(spreadsheetImport) ? "Upload from Spreadsheet" : "New Delivery"}</h5>
                                </Col>
                                <Col align='right'>
                                    <Button onClick={() => setSpreadsheetImport(!spreadsheetImport)}>
                                        { (spreadsheetImport) ? "Manual Import" : "Spreadsheet Import" }
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {
                                (spreadsheetImport)
                                ? <ImportBySpreadsheet  user={user} addDeliveries={addDeliveries}/>
                                : <ImportManually       user={user} addDeliveries={addDeliveries}/>
                            }
                        </Card.Body>
                    </Card>
                    <ImportedDeliveriesList deliveries={nextDeliveries} removeDelivery={removeDelivery}/>
                </Col>
            </Row>
        </>     
    )
}

ImportDeliveries.propTypes = {
    user: PropTypes.object,
    nextDeliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
    setNextDeliveries: PropTypes.func.isRequired
}

export default ImportDeliveries;