import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, FormCheck, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';

function ImportBySpreadsheet({user, addDeliveries}) {

    const [deliverySpreadsheet, setDeliverySpreadsheet] = useState(null);
    const [useDefaultAddress, setUseDefaultAddress] = useState(true);
    const [pickupIndex, setPickupIndex] = useState(null);

    const uploadSpreadsheet = async (event) => {
        event.preventDefault();
        const spreadsheetUploadEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/spreadsheet`;
        const formData = new FormData();
        formData.append('file', deliverySpreadsheet);

        try {
            const res = await axios.post(
                spreadsheetUploadEndpoint,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            )

            const parsedDeliveries = res.data.data.deliveries;
            handleSave(parsedDeliveries);
            setDeliverySpreadsheet(null);

        } catch (error) {
            console.log(error)
        }
    }

    const handleSave = parsedDeliveries => {
        const deliveriesWithOptions = parsedDeliveries.map(delivery=> ({
            ...delivery, 
            pickupIndex: pickupIndex, 
        }));
        addDeliveries(deliveriesWithOptions)
    }


    return(
        <>
            <Form onSubmit={uploadSpreadsheet} >
                <Row>
                    <Col className="p-3">
                        <Form.File className='custom-file'>
                            <Form.File.Label>
                                {
                                    'Delivery Spreadsheet (.csv only)'
                                }
                            </Form.File.Label>
                            <Form.File.Input onChange={event => setDeliverySpreadsheet(event.target.files[0])}/>
                            <Link 
                                to="/downloadables/deliveries-template.csv" 
                                target="_blank" 
                                download
                            >
                            Download a template
                        </Link>
                        </Form.File>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormCheck
                            type="switch"
                            id='default-address'
                            label='Use default pickup address'
                            checked={useDefaultAddress}
                            onChange={() => {
                                setUseDefaultAddress(!useDefaultAddress);
                                setPickupIndex(useDefaultAddress ? null : 1);
                            }}
                        />                                                      
                    </Col>
                </Row>
                <Row hidden={useDefaultAddress}>
                    <Col xs='4'>
                        <Form.Group 
                            controlId="pickupIndex" 
                            onChange={event => setPickupIndex(event.target.value)}
                        >
                            <Form.Control 
                                as="select"
                                custom
                                onClick={event => setPickupIndex(event.target.value)}
                            >
                                {
                                (user) 
                                ? 
                                    user.address.map((address, index) => (
                                        <option value={index}>
                                            {`${address.number} ${address.street}`}
                                        </option>
                                    ))
                                : 
                                    <option readOnly>No addresses found</option>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs='auto'> 
                        <Button type='submit' disabled={!deliverySpreadsheet}>
                            Import
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>     
    )
}

ImportBySpreadsheet.propTypes = {
    addDeliveries: PropTypes.func.isRequired,
    user: PropTypes.object
}

export default ImportBySpreadsheet;