import axios from 'axios';

const doDeliveryExport = async (apiKey) => {

    const exportEndpoint = process.env.REACT_APP_DELIVERIES_ENDPOINT + '/spreadsheet/export'

    const headers = {
        'X-API-Key': apiKey,
        responseType: 'blob'
    }

    try {
        const res = await axios.get(
            exportEndpoint, 
            {headers:headers}
        )
        let date = new Date(Date.now());
        let fileName = `deliveries-${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}.csv`

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        return res.data;

    } catch (err) {
        console.log(err);
        return null;
    }
}

export default doDeliveryExport;