import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ProgressBar, ListGroup, Table, Card, Spinner, Button, ButtonGroup, Form, Dropdown, DropdownButton, FormCheck, FormControl } from 'react-bootstrap';

import StartDateTimePicker from '../date-time-filter/CurrentDeliveriesStartDateTimePicker.component';
import EndDateTimePicker from '../date-time-filter/CurrentDeliveriesEndDateTimePicker.component';

function MoreDetailsFilter({setStartDate, setEndDate, setSortByFilter, deliveries, moreDetailsDelivery, setMoreDetailsDelivery}) {
    const [toggleEndDateTime, setToggleEndDateTime] = useState(false);
    const [toggleStartDateTime, setToggleStartDateTime] = useState(false);

    useEffect(() => {
        if (toggleStartDateTime) {
            setToggleEndDateTime(false)
        }
    }, [toggleStartDateTime])

    useEffect(() => {
        if (toggleEndDateTime) {
            setToggleStartDateTime(false)
        }
    }, [toggleEndDateTime])

    const handleClickDeliveriesList = (delivery) => {
        setMoreDetailsDelivery(delivery)
    }

    return (
        <Row className='wrapper'>
            <Card className="CurrentDeliveriesFilter">
                <h4 style={{ paddingLeft: '20px', paddingTop: '20px', marginBottom: '-25px' }}>
                    Summary of Deliveries
                    <hr style={{ margin: '17px -20px' }} />
                </h4>
                <Card.Body style={{ display: 'inline' }}>
                    <h5 style={{ fontSize: '1.1vw' }}>
                        DATE AND TIME
                    </h5>
                    <div style={{ display: 'flex', paddingLeft: '10px', marginRight: '1vw', fontSize: '0.8vw' }}>
                        <label id='startLabel'>START:</label>
                        <StartDateTimePicker
                            setStartDate={setStartDate}
                            toggleStartDateTime={toggleStartDateTime}
                            setToggleStartDateTime={setToggleStartDateTime}
                        />
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '10px', marginRight:'1vw', fontSize: '0.8vw' }}>
                        <label id='endLabel'>END:</label>
                        <EndDateTimePicker
                            setEndDate={setEndDate}
                            toggleEndDateTime={toggleEndDateTime}
                            setToggleEndDateTime={setToggleEndDateTime}
                        />
                    </div>
                    <DropdownButton
                        variant="white"
                        title="SORT BY"
                        size="xs"
                        style={{ marginLeft: '-15px'}}
                    >
                        <Dropdown.Item onClick={() => { setSortByFilter('RecipientName') }}>
                            Recipient Name
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSortByFilter('MostRecent') }}>
                            Most Recent
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSortByFilter('OldestToNewest') }}>
                            Oldest to Newest
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSortByFilter('Status') }}>
                            Status
                        </Dropdown.Item>
                    </DropdownButton>
                    <div style={{ height: '60vh', overflowY: 'scroll' }}>
                            <Table bordered hover striped style={{}}>
                                <tbody>
                                    {(deliveries) ? deliveries.map((delivery, index) => (
                                        <tr>
                                            {
                                                <td style={{ textAlign: 'left', verticalAlign: 'middle' }}
                                                    className={(delivery.id === moreDetailsDelivery.id) ? "moreDetailsCurrentDelivery" : ""} 
                                                    onClick={(delivery) => {
                                                        handleClickDeliveriesList(deliveries[index])
                                                }}>
                                                    <p>{`${delivery.recipientName}, ${delivery.addressNumber} ${delivery.addressStreet}.`}</p>
                                                </td>
                                            }
                                        </tr>
                                    )):[]}
                                </tbody>
                            </Table>
                    </div>
                </Card.Body>
            </Card>
        </Row>
    )
};

MoreDetailsFilter.propTypes = {
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    setSortByFilter: PropTypes.func.isRequired,
    deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
    moreDetailsDelivery: PropTypes.object.isRequired,
    setMoreDetailsDelivery: PropTypes.func.isRequired,

};

export default MoreDetailsFilter;