import axios from 'axios';

const fetchFilteredDeliveries = async (apiKey, selectedStatuses, selectedZones, selectedPickUp, selectedGiftSenders, startDate, endDate, sortByFilter, page, pageLimit) => {
    const endpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/deliveries/filtered`
    const headers = {
        'X-API-Key': apiKey
    }
    try {
        const res = await axios.post(
            `${endpoint}`,
            {
                selectedStatuses: selectedStatuses, selectedZones: selectedZones, selectedPickUp: selectedPickUp, selectedGiftSenders: selectedGiftSenders,
                startDate: startDate, endDate: endDate, sortByFilter: sortByFilter, page: page, pageLimit: pageLimit
            },
            { headers: headers }
        )
        let deliveries = res.data.data.deliveries

        let formattedDeliveries = deliveries.map(delivery => {
            //determine status from status timestamps 
            let status;
            if (delivery.status.failed || delivery.status.completed) {
                if (delivery.status.failed) { status = 'failed'; }
                if (delivery.status.completed) { status = 'completed'; }
            }

            else if (delivery.status.inProgress) { status = 'inProgress'; }

            else if (delivery.status.active) {
                let activeTime = new Date(delivery.status.active)
                let readyTime = new Date(delivery.status.pickupReady)
                status = ( activeTime > readyTime ) 
                ?  'active' 
                :  'submitted' /* READYFORPICKUP DISABLE should be 'pickupReady' */
            }
            
            else if (delivery.status.pickupReady)   {status = 'pickupReady'}
            else if (delivery.status.submitted)      { status = 'submitted' }

            let created = new Date(delivery.createdAt);
            let statusTime = new Date(delivery.status[status])

            let eta = null;

            let formattedDelivery = {
                id: delivery._id,
                onfleetId: delivery.onfleetId,
                onfleetShortId: delivery.onfleetShortId,

                clientName: delivery.client.companyName,

                clientAddressNumber: delivery.client.address.number,
                clientAddressStreet: delivery.client.address.street,
                clientAddressUnit: (delivery.client.address.unit) ? delivery.client.address.unit : null,
                clientCity: delivery.client.address.city,
                clientProvince: delivery.client.address.province,
                clientCountry: delivery.client.address.country,
                clientPostalCode: delivery.client.address.postalCode,

                recipientName: delivery.recipient.name,
                recipientPhone: delivery.recipient.phone,

                addressNumber: delivery.address.number,
                addressStreet: delivery.address.street,
                addressUnit: (delivery.address.unit) ? delivery.address.unit : null,
                city: delivery.address.city,
                province: delivery.address.province,
                country: delivery.address.country,
                postalCode: delivery.address.postalCode,
                zone: delivery.address.zone,

                deliveryNotes: (delivery.options) ? delivery.options.deliveryNotes : null,
                quantity: (delivery.options) ? delivery.options.quantity : null,
                giftSender: (delivery.options) ? delivery.options.giftSender : null,

                status: status,
                statusTime: statusTime,
                statuses: delivery.status,
                arrivalTime: eta,
                created: created,
                coordinates: {
                    lng: delivery.address.coordinates[0],
                    lat: delivery.address.coordinates[1]
                },

                completionNotes: (delivery.completionData.driverNotes) ? delivery.completionData.driverNotes : null,
                failureNotes: (delivery.completionData.failureNotes) ? delivery.completionData.failureNotes : null,
                failureReason: (delivery.completionData.failureReason) ? delivery.completionData.failureReason : null,
                completionPhotos: (delivery.completionData.photoLinks) ? delivery.completionData.photoLinks : null,
                completionSignature: (delivery.completionData.signatureLink) ? delivery.completionData.signatureLink : null
            }
            return formattedDelivery
        })

        return { formattedDeliveries: formattedDeliveries, numDeliveries: res.data.data.numDeliveries }
    } catch (err) {
        console.log(err)
    }
}

export default fetchFilteredDeliveries;