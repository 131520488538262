import React from 'react';
import PropTypes from 'prop-types'
import {Card, Row, Col} from 'react-bootstrap';

import TayzaLogo from '../../../assets/logo-full.png';

function SidebarHeader ({companyName}) {
    return (
        <Card className="SidebarItem">
            <Card.Header className="SidebarHeader">
                <Row>
                    <Col align='center'>
                        <img src={TayzaLogo} alt="Tayza Logo" width="60%"/>
                        <br/>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <i> Signed in as: </i>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5> {(companyName) ? companyName : "Tayza User"} </h5>
                    </Col>
                </Row>
            </Card.Header>
        </Card>
    )
}

SidebarHeader.propTypes = {
    companyName: PropTypes.string
}

export default SidebarHeader;