import { Form, Row, Col, Button} from "react-bootstrap";
import React, {useState, useEffect} from 'react'; 

function DeliveryTrackingSearch (delivery) {

    const[id, setId] = useState("");
    const[statusUpdate, setStatusUpdate] = useState("");
    const[time, setTime] = useState("");

    useEffect(() => {
        refreshStatusUpdate();
    }, [delivery])

    const refreshStatusUpdate = () => {
        console.log(delivery.delivery)
        if(Object.keys(delivery.delivery).length != 0){
            let status;
            if (delivery.delivery.status.failed || delivery.delivery.status.completed) {
                if (delivery.delivery.status.failed) { 
                    status = 'failed'; 
                }
                if (delivery.delivery.status.completed) { 
                    status = 'completed'; 
                }
            }
            else if (delivery.delivery.status.inProgress) { 
                status = 'inProgress'; 
            }
            else if (delivery.delivery.status.active) {
                let activeTime = new Date(delivery.delivery.status.active)
                let submittedTime = new Date(delivery.delivery.status.submitted)
                status = ( activeTime > submittedTime ) 
                ?  'active' 
                :  'submitted';
            }      
            else if (delivery.delivery.status.submitted) { 
                status = 'submitted';
            }

            let update;
            switch(status){
                case 'failed':
                    update = 'Failed';
                    break;
                case 'completed':
                    update = 'Delivered';
                    break;
                case 'inProgress':
                    update = 'On Route'; 
                    break;
                case 'active':
                    update = 'Assigned';
                    break;
                default:
                    update = 'Submitted';
            }
    
            let timeStamp = new Date(delivery.delivery.status[status]);
    
            setTime(timeStamp.toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}));
            setStatusUpdate(update);
            setId(delivery.delivery.id);
        }
    }

    return(
        <React.Fragment>
            <div className="smallDeliveryID">
                <p>{id}</p>
            </div>            
            <div className="status">
                <h1>{statusUpdate}</h1>
            </div>
            <div className="statusTime">
                <p>{time}</p>
            </div>
        </React.Fragment>
    );
}

export default DeliveryTrackingSearch;