import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Spinner, Form } from 'react-bootstrap';

import createDelivery from '../../../services/createDelivery.service';
import validateAllPickups from '../../../services/validateAllPickups.service';
import checkPackageType from '../../../services/checkPackageType.service';

function UploadDeliveries({
    user, 
    nextDeliveries, 
    setNextDeliveries, 
    uploadedDeliveries, 
    setUploadedDeliveries, 
    refreshDeliveries,
    deliveryZones
}) {
    
    const [failedCount, setFailedCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(uploadedDeliveries.length > 0);
    const [isBatch, setIsBatch] = useState(nextDeliveries.length > 100);
    const [readyForPickup, setReadyForPickup] = useState((user.preferences.pickupReadyDefault) ? user.preferences.pickupReadyDefault : false);
    const [failedDeliveriesList, setFailedDeliveriesList] = useState([]);

    useEffect(()=> {
        const setDisplayDeliveries = async () => {
            const displayPromises = await nextDeliveries.map(async (delivery) => {
                let deliveryCopy = {...delivery}
                
                deliveryCopy.uploading = false;
                deliveryCopy.complete = false;
                deliveryCopy.failed = false;
                
                const zoneFilter = deliveryZones.filter(zone => {
                    const deliveryFSA = delivery.postalCode
                        .trim()
                        .substring(0,3)
                        .toUpperCase();
                    
                    return zone.postalCodes.includes(deliveryFSA);
                });
    
                if(delivery.length && delivery.width && delivery.height){
                    const dimensions = {
                        length: delivery.length,
                        width: delivery.width,
                        height: delivery.height,
                        weight: delivery.weight
                    }
    
                    const priceMultiplier = await checkPackageType(dimensions);
                    if(priceMultiplier){
                        deliveryCopy.priceMultiplier = priceMultiplier;
                    }
                }
    
                if (zoneFilter.length > 0) deliveryCopy.zone = zoneFilter[0];
                return deliveryCopy;
            });
            
            const displayDeliveries = await Promise.all(displayPromises);
            setNextDeliveries(displayDeliveries);
        }

        setDisplayDeliveries();
    }, []);

    function delay(t, data) {
        return new Promise(resolve => {
            setTimeout(resolve.bind(null, data), t);
        });
    }

    
    const updateTime = (date, time) => {
        if(!time)return null
        date.setHours(Math.round(time/60))
        date.setMinutes(time%60)
        return date.getTime()
    }

    const formatUnixTime = () => {
        const currentDate = new Date();
        currentDate.setSeconds(0)
        currentDate.setMilliseconds(0)
        const currentWeekDay = currentDate.toLocaleDateString(undefined, {weekday: 'long'}).toLowerCase()
        const pickupTimes = user.preferences.pickupTimes[currentWeekDay];
        const startUnixTime = updateTime(currentDate, pickupTimes.start)
        const endUnixTime = updateTime(currentDate, pickupTimes.end)
        return {start: startUnixTime, end: endUnixTime}
    }

    const uploadDelivery = async (delivery) => {
        const pickupUnixTimes = formatUnixTime()
        if(!pickupUnixTimes.start || pickupUnixTimes.end < new Date().getTime()){
            pickupUnixTimes.start = undefined;
            pickupUnixTimes.end = undefined;
        }
        const apiKey = user.apiKey;

        const deliveryUpload = {
            address: {
                unit:delivery.addressUnit,
                number:delivery.addressNumber,
                street:delivery.addressStreet,
                city:delivery.city,
                province:delivery.province,
                country:delivery.country,
                postalCode:delivery.postalCode
            },
            recipient: {
                name:delivery.recipientName,
                phone:delivery.recipientPhone
            },
            options: {
                quantity:delivery.quantity,
                deliveryNotes: delivery.notes,
                pickupIndex: delivery.pickupIndex,
                giftSender: (delivery.giftSender) ? delivery.giftSender : null,
                realPrice: Math.round(100 * (delivery.zone.price * ((delivery.priceMultiplier) ? (delivery.priceMultiplier) : 1) + Number.EPSILON)) / 100,
                useSmartServe: ('useSmartServe' in delivery) ? delivery.useSmartServe : user.preferences.useSmartServe,
                completeAfter: pickupUnixTimes.start,
                completeBefore: pickupUnixTimes.end,
            },
            pickupReady: readyForPickup
        }

        try {
            const res = await createDelivery(deliveryUpload, apiKey);
            if (res) return res;
            else return false;
        }
        catch(err) {
            console.log(err)
            return false;
        } 
    }

    const handleBatchUpload = async (retry=false) => {
        setUploading(true);
        setUploadComplete(false);        
        setNextDeliveries(
            nextDeliveries.map(delivery => (
                (!retry || (retry && delivery.failed)) 
                ? {
                    ...delivery,
                    uploading: true,
                    complete: false,
                    failed: false
                } : {...delivery}
            ))
        );

        let uploadsRemaining = nextDeliveries.filter(delivery => delivery.uploading).length;

        await uploadAll();

        async function uploadAll() { 
            // initializing iteration conditions
            let currentIndex = 0;
            let batchSize = 500;
            let nextIndex = currentIndex + batchSize;
            let endIndex = nextDeliveries.length;
            
            if (endIndex < batchSize) { //if the list of deliveries is less than the specified batch size
                nextIndex = endIndex;
            }
            
            //recursive function for delaying delivery addition
            function next() {
                if (nextIndex <= endIndex) {
                    return recursiveUpload(currentIndex, nextIndex).then((thing) => {
                        if (nextIndex === endIndex) {
                            nextIndex += 1;
                        }
                        else {
                            nextIndex = nextIndex + batchSize > endIndex ? endIndex : nextIndex + batchSize;
                        }
                        currentIndex += batchSize;
                        return next(); 
                    });
                }
            }
            return Promise.resolve().then(next);
        }


        //uploading delivery but only uploads from currentIndex to nextIndex
        async function recursiveUpload(currentIndex, nextIndex) {
            await Promise.all(nextDeliveries.slice(currentIndex, nextIndex).map(async (delivery, index) => {
                if (!retry || delivery.failed) {
                    try {
                        let uploadedId = await uploadDelivery(delivery);

                        delivery.uploading = false;
                        delivery.failed = !uploadedId;
                        delivery.complete = !!uploadedId;
                        delivery.id = uploadedId;
                    }

                    catch(err) {
                        console.log(err)
                    }
                    finally {
                        return Promise.resolve(delivery);
                    }
                }
            })).then(async (values) => {
                refreshDeliveries();

                // validate once, since all deliveries in a batch are part of the same onfleet team
                await validateAllPickups(user.apiKey);

                // get failed count 
                uploadsRemaining = nextDeliveries.filter(delivery => delivery.uploading).length
                    
                if (!uploadsRemaining) {
                    const failedDeliveriesArray = nextDeliveries.filter(delivery => (delivery.failed));
                    setFailedDeliveriesList(failedDeliveriesArray);
                    const failedDeliveries = failedDeliveriesArray.length;
                    setUploading(false);
                    setUploadComplete(true);
                    setFailedCount(failedDeliveries);
                }   
            })
            return 1;
       }
}

const handleUpload = async (retry=false) => {
    setUploading(true);
    setUploadComplete(false);        
    setNextDeliveries(
        nextDeliveries.map(delivery => (
            (!retry || (retry && delivery.failed)) 
            ? {
                ...delivery,
                uploading: true,
                complete: false,
                failed: false
            } : {...delivery}
        ))
    );

    let uploadsRemaining = nextDeliveries.filter(delivery => delivery.uploading).length;
        Promise.all(nextDeliveries.map(async (delivery, index) => {
            if (!retry || delivery.failed) {
                try {
                    let uploadedId = await uploadDelivery(delivery);
                    let newDeliveries = [...nextDeliveries];

                    delivery.uploading = false;
                    delivery.failed = !uploadedId;
                    delivery.complete = !!uploadedId;
                    delivery.id = uploadedId;

                    newDeliveries[index] = delivery;
                    setNextDeliveries(newDeliveries);

                    if (uploadedId) {
                        refreshDeliveries();
                        setUploadedDeliveries([...uploadedDeliveries, uploadedId]);
                    }
                }

                catch(err) {
                    console.log(err)
                }
                finally {
                    uploadsRemaining = nextDeliveries.filter(delivery => delivery.uploading).length
                        
                    if (!uploadsRemaining) {
                        const failedDeliveries = nextDeliveries.filter(delivery => (delivery.failed)).length;
                        setUploading(false);
                        setUploadComplete(true);
                        setFailedCount(failedDeliveries);
                    }   
                    return Promise.resolve(delivery);
                }
            }
        })).then(async () => {
            try{
                await validateAllPickups(user.apiKey);
            }
            catch(err){
                console.log(err)
            }
        })
    }

    /* READYFORPICKUP DISABLE

                <Form.Check
            type='checkbox'
            disabled={uploading || uploadComplete}
            checked={readyForPickup}
            onClick={() => setReadyForPickup(!readyForPickup)}
            label={(nextDeliveries.length > 1) ? `Deliveries are currently ready for pickup` : `Delivery is currently ready for pickup`} 
            >
                        </Form.Check>
    */

    return(
        <>
        {
        (isBatch) ?
            null
        :
            <h6> Summary of Deliveries:</h6>
        }

        {
        (failedCount > 0) 
        ? 
            <h7> Failed {failedCount} Deliveries: </h7>
        :
            null
        }
        
        {
        (isBatch) ?  
            null
        :
            nextDeliveries.map( delivery => {
                return (
                    <div>
                        <hr/>
                        <Row>
                            <Col xs='1'>
                                {(delivery.uploading)   ? <Spinner animation="grow"/> : null}
                                {(delivery.complete)    ? <h1>&#10003;</h1> : null}
                                {(delivery.failed)      ? <h1>&#10005;</h1> : null}
                            </Col>

                            <Col xs='6'>
                                {`${delivery.recipientName}`}
                                <br/> 
                                {(delivery.addressUnit) ? `${delivery.addressUnit} - ` : ''} 
                                {`${delivery.addressNumber} ${delivery.addressStreet} ,`} 
                                {`${delivery.city} ${delivery.province} ${delivery.country}, `}
                                {`${delivery.postalCode} `}
                                <br/>
                                {`quantity: ${(delivery.quantity) ? delivery.quantity : 'n/a'}`}
                            </Col>
                            <Col xs='5'>
                                {`notes: ${(delivery.notes) ? delivery.notes : 'n/a'}`}
                                <br/>
                                {`gift sender: ${(delivery.giftSender ? delivery.giftSender : 'n/a')}`}
                                <br/>
                                {`cost: ${(delivery.zone) ? `$${Math.round(100 * (delivery.zone.price * ((delivery.priceMultiplier) ? (delivery.priceMultiplier) : 1) + Number.EPSILON)) / 100}` : 'default'}`}
                            </Col>
                        </Row>
                    </div>
                )
            })
        }
        {
        (failedDeliveriesList)
        ?
        failedDeliveriesList.map( delivery => {
            return (
                <div>
                    <hr/>
                    <Row>
                        <Col xs='1'>
                            {(delivery.complete)    ? <h1>&#10003;</h1> : null}
                            {(delivery.failed)      ? <h1>&#10005;</h1> : null}
                        </Col>

                        <Col xs='6'>
                            {`${delivery.recipientName}`}
                            <br/> 
                            {(delivery.addressUnit) ? `${delivery.addressUnit} - ` : ''} 
                            {`${delivery.addressNumber} ${delivery.addressStreet} ,`} 
                            {`${delivery.city} ${delivery.province} ${delivery.country}, `}
                            {`${delivery.postalCode} `}
                            <br/>
                            {`quantity: ${(delivery.quantity) ? delivery.quantity : 'n/a'}`}
                        </Col>
                        <Col xs='5'>
                            {`notes: ${(delivery.notes) ? delivery.notes : 'n/a'}`}
                            <br/>
                            {`gift sender: ${(delivery.giftSender ? delivery.giftSender : 'n/a')}`}
                            <br/>
                            {`cost: ${(delivery.zone) ? `$${Math.round(100 * (delivery.zone.price * ((delivery.priceMultiplier) ? (delivery.priceMultiplier) : 1) + Number.EPSILON)) / 100}` : 'default'}`}
                        </Col>
                    </Row>
                </div>
            )
        })
        :
        null
        }
            <br/>
            <Button
            variant={
                ( uploading || !failedCount )
                ? 'primary'
                : 'danger'
            }
                onClick= {() => {
                    (isBatch) ? handleBatchUpload(failedCount > 0) : handleUpload(failedCount > 0)
                }}
                disabled={(uploading || (uploadComplete && failedCount < 1))}
                block
            >
                {
                    (uploading)
                    ? `uploading...`
                    : (!uploadComplete) 
                        ? `Submit ${nextDeliveries.length} deliveries`
                        : (failedCount < 1)
                            ? `upload successful`
                            : `Retry (${failedCount} failed)`
                }
            </Button>
        </>
    )
}

UploadDeliveries.propTypes = {
    user: PropTypes.object, 
    nextDeliveries: PropTypes.arrayOf(PropTypes.object), 
    setNextDeliveries: PropTypes.func, 
    uploadedDeliveries: PropTypes.array, 
    setUploadedDeliveries: PropTypes.func, 
    refreshDeliveries: PropTypes.func,
    // deliveryZones: PropTypes.arrayOf(PropTypes.object)
}

export default UploadDeliveries;