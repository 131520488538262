import React, {useState, useEffect} from 'react'; 
import { Navbar } from 'react-bootstrap';
import Logo from '../../assets/logo.png';
import StartDateTimePicker from './dateTimePicker/datepicker/StartDateTimePicker.component'
import EndDateTimePicker from './dateTimePicker/datepicker/EndDateTimePicker.component'
import PropTypes from 'prop-types';


function HistoryPageHeader({refreshDeliveries}) {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        (startDate && endDate) && refreshDeliveries(startDate, endDate);
    }, [startDate, endDate])

    return (
        <Navbar fixed="top" bg="primary" variant="dark" expand="lg">
             <Navbar.Brand href = '/'>
                <img
                    src={Logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Tayza T Logo"
                /> 
                <em>Fulfillment History</em>
            </Navbar.Brand>
        <label id = 'startLabel' className= "dateLabel">FROM:</label>
        <StartDateTimePicker setStartDate = {setStartDate}/>
        <label id = 'endLabel' className= "dateLabel">TO:</label>
        <EndDateTimePicker setEndDate = {setEndDate}/>
        </Navbar>
        
    )
}

HistoryPageHeader.propTypes = {
    refreshDeliveries: PropTypes.func.isRequired
};


export default HistoryPageHeader;