import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem.component';
import DeliverySubmenuItem from './DeliverySubmenuItem.component';
import AddDeliveries from './AddDeliveries.component';
import DeliveryDetail from '../DeliveryDetail.component';

import CurrentDeliveriesButton from './CurrentDeliveriesButton.component';

import deleteDelivery from '../../../services/deleteDelivery.service';
import validateAllPickups from '../../../services/validateAllPickups.service';
import LabelButton from './LabelButton.component';

import DeliveriesIcon from '../../../assets/delivery.svg';

import {Row, Col, ListGroup, Button, ButtonGroup, Modal, ProgressBar} from 'react-bootstrap';

function Deliveries({user, deliveries, refreshDeliveries}) {
    const [globalList, setGlobalList] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [show, setShow] = useState(false);
    const [warning, setWarning] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [progressLabel, setProgressLabel] = useState(0);
    const [originalListLength, setOriginalListLength] = useState(0);
    const [pickUpAddresses, setPickUpAdresses] = useState([]);
    const [showPickUp, setShowPickUp] = useState([]);

    useEffect(() => {
        if (user) {
            setPickUpAdresses(user.address);
        }
    }, [user]);

    useEffect(() => {
        disableToggle();
        if (globalList.length !== 0) {
            setShow(true);
        }
        else {
            setShow(false);
        }
    }, [globalList]);

    useEffect(() => {
        if (deliveries){
            handleShowPickUp();
            filterShowPickUp();
        }
    }, [deliveries]);

    const handleGlobalList = (delivery) => {
        if (globalList.filter(item => item.id === delivery.id).length === 1) {
            setGlobalList(prevList => prevList.filter(item => item.id !== delivery.id));
        }
        else {
            setGlobalList(prevList => prevList.concat(delivery));
        }
    }

    const removeDelivery = () => {
        setInProgress(true);
        Promise.all (
        globalList.map(async (delivery) => {
            return deleteDelivery(delivery.id, user.apiKey).then(() => {
                setProgressLabel(prevLabel => prevLabel + 1);
                setGlobalList(prevList => prevList.filter(item => item !== delivery));
                refreshDeliveries();
            });
        }))
        .then(async () => {
            await validateAllPickups(user.apiKey);
            closeWarning();
            setInProgress(false);
            setProgressLabel(0);
        })
        .catch(err => alert(err));
    }
    
    const disableToggle = () => {
        let count = 0;
        globalList.map((delivery) => {
            if (["completed", "failed", "inProgress"].includes(delivery.status)) {
                setDisableButton(true);
                count ++;
            }
        })
        if (count === 0) {
            setDisableButton(false);
        }
    }

    const handleSelected = (id) => {
        let found = false;
        globalList.map((delivery) => {
            if (delivery.id === id) {
                found = true;
            }
        });
        return found;
    }

    const openWarning = () => {
        setOriginalListLength(globalList.length);
        setWarning(true);
    }

    const closeWarning = () => {
        setWarning(false);
    }

    const handleShowPickUp = () => { 
        deliveries.map((delivery) => {
            if (showPickUp.filter(item => item === `${delivery.clientAddressNumber} ${delivery.clientAddressStreet}`).length < 1) {
                setShowPickUp(prevList => prevList.concat(`${delivery.clientAddressNumber} ${delivery.clientAddressStreet}`));
            }
        });
    }

    const filterShowPickUp = () => {
        if (showPickUp) {
            showPickUp.map((pickUpAddress) => {
                let found = false;
                deliveries.map((delivery) => {
                    if (pickUpAddress === `${delivery.clientAddressNumber} ${delivery.clientAddressStreet}`) {
                        found = true;
                    }
                });
                if (!(found)) {
                    setShowPickUp(prevList => prevList.filter(item => item !== pickUpAddress));
                }
            });
        }
    }

    return (
        <SidebarItem icon={{img:DeliveriesIcon, alt:'deliveries'}} title="Deliveries">
            <CurrentDeliveriesButton/>
            { 
            (pickUpAddresses)
            ?
            pickUpAddresses.map((pickUpAddress) => {
                if (showPickUp.includes(`${pickUpAddress.number} ${pickUpAddress.street}`)) {
                    return (
                        <SidebarItem title={`${pickUpAddress.number} ${pickUpAddress.street}`}>
                            {
                                (deliveries) 
                                ? 
                                    deliveries.map((delivery, index, array) => {
                                        if (delivery.clientAddressNumber === pickUpAddress.number && delivery.clientAddressStreet === pickUpAddress.street) {
                                            return (    
                                                <>
                                                    { 
                                                        ( 
                                                            index > 0 && 
                                                            ( 
                                                                ["submitted", "active", "inProgress"].includes(array[index-1].status) 
                                                                && ["completed", "failed"].includes(delivery.status)
                                                            ) 
                                                        ) 
                                                        ? <br/> 
                                                        : null 
                                                    }
                                                    <DeliveryDetail 
                                                        delivery={delivery}
                                                        refreshDeliveries={refreshDeliveries}
                                                        apiKey={user.apiKey}
                                                        useLabelPrinter={user.preferences.useLabelPrinter ? true : false}
                                                        recordSelected={handleGlobalList}
                                                    >
                                                        <DeliverySubmenuItem
                                                            selected={handleSelected(delivery.id)}
                                                            key={delivery.id}
                                                            name={delivery.recipientName}
                                                            status={delivery.status}
                                                            address={
                                                                (delivery.addressUnit) 
                                                                ? delivery.addressUnit + ' - ' + delivery.addressNumber + ' ' + delivery.addressStreet
                                                                : delivery.addressNumber + ' ' + delivery.addressStreet
                                                            }
                                                        />
                                                        {}
                                                    </DeliveryDetail>
                                                </>    
                                            )
                                        }
                                    })
                                : null
                            }
                        </SidebarItem>
                    )
                }
            })
            :
            null
            }
            <br/>
            {
                show 
                ?
                    <ButtonGroup style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Button
                            style={{width: '40%'}}
                            variant="secondary" 
                            onClick={openWarning}
                            disabled={disableButton}
                        >
                            Delete
                        </Button>
                        <LabelButton 
                            apiKey={user.apiKey} 
                            deliveries={globalList.filter(upload => !!upload.id)} 
                            useLabelButton={user.preferences.useLabelPrinter ? true : false}
                        />
                    </ButtonGroup>
                :             
                <AddDeliveries 
                    user={user} 
                    refreshDeliveries={refreshDeliveries}
                    deliveries={deliveries}
                /> 
            }
            <Modal show={warning} onHide={closeWarning}>
                <Modal.Header closeButton>
                    {inProgress ? `Deleting ${originalListLength} deliveries`
                    :
                    `Are you sure you want to delete ${originalListLength} ${originalListLength === 1 ? "delivery" : "deliveries"}?`
                    }
                </Modal.Header>
                <Modal.Body>
                    { inProgress ? <ProgressBar  variant="primary" animated="true" label={`${progressLabel} / ${originalListLength}`} now={progressLabel/originalListLength * 100}/>
                    :
                    globalList.map((delivery) => 
                        <ListGroup.Item>
                            <Row>
                                <Col>
                                    <strong>{delivery.recipientName}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <em>
                                        {   (delivery.addressUnit) 
                                            ? delivery.addressUnit + ' - ' + delivery.addressNumber + ' ' + delivery.addressStreet
                                            : delivery.addressNumber + ' ' + delivery.addressStreet
                                        }
                                    </em>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        removeDelivery();
                    }}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={closeWarning}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <br/>
        </SidebarItem>
    )
}

Deliveries.propTypes = {
    user: PropTypes.object,
    deliveries: PropTypes.arrayOf(PropTypes.object),
    refreshDeliveries: PropTypes.func
}


export default Deliveries;