import React from 'react';
import { Button } from 'react-bootstrap';
import BackArrow from '../../assets/back-arrow.svg';

function ReturnButton() {
    return (
        <Button href='/' variant='secondary'>
            <img
                src={BackArrow}
                alt="return to home page"
                width='18vh'   
            />
            &nbsp;
            Back to Dashboard
        </Button>
    );
};

export default ReturnButton;