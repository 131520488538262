import React from 'react'; 
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'react-bootstrap';

function CurDelPaginationControls ({page, lastPage, setPage}) {

    const onFirstPage = (page === 0);
    const onLastPage  = (page === lastPage);

    const prevPage = page - 1;
    const nextPage = page + 1;

    return (
        <ButtonGroup>
            {
            (onFirstPage) 
            ?
                <h6 style={{paddingRight:'10px', color:'#808080'}}>
                    FIRST
                </h6> 
            :
                <h6
                    style={{paddingRight:'10px'}}
                    onClick={() => setPage(0)}
                >
                    FIRST
                </h6>
            }
            { 
            (onFirstPage) 
            ?
                <h6 style={{paddingRight:'10px', color:'#808080'}}>
                    PREV
                </h6>
            :
                <h6
                    style={{paddingRight:'10px'}}
                    onClick={() => setPage(prevPage)}
                >
                    PREV
                </h6>
            }
            <h6>{`| ${page + 1} |`}</h6> 
            {
            (onLastPage) 
            ?
                <h6 style={{paddingRight:'10px', paddingLeft:'10px', color:'#808080'}}>
                    NEXT
                </h6>
            :
                <h6
                    style={{paddingRight:'10px', paddingLeft:'10px'}}
                    onClick={() => setPage(nextPage)}
                >
                    NEXT
                </h6>
            }
            {
            (onLastPage) 
            ?
                <h6 style={{paddingRight:'10px', color:'#808080'}}>
                    LAST
                </h6>
            :
                <h6
                    style={{paddingRight:'10px'}}
                    onClick={() => setPage(lastPage)}
                >
                    LAST
                </h6>
            }
        </ButtonGroup>
    );
};

CurDelPaginationControls.propTypes = {
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired
};

export default CurDelPaginationControls;