import React,{ useState, useEffect} from "react"
import PropTypes from 'prop-types';

import Days from './Days.component'
const Month = ({dateProp, SelectedDateUpdate, updateDate, monthUpdate, selectedDate}) => {
    const [date, setDate] = useState(new Date(dateProp))
    const [prevMonthDate, setPrevMonthDate] = useState(new Date(dateProp).setMonth(dateProp.getMonth() - 1))
    const [nextMonthDate, setNextMonthDate] = useState(new Date(dateProp).setMonth(dateProp.getMonth() + 1))

    useEffect(() => {
        updateDate(date.getMonth(), date.getFullYear())
        setPrevMonthDate(new Date(date).setMonth(date.getMonth() - 1))
        setNextMonthDate(new Date(date).setMonth(date.getMonth() + 1))
    }, [date])

    useEffect(() => {
        setDate(new Date(dateProp))
    }, [monthUpdate])

    const nextMonth = () => {
        let tempDate = new Date(date);
        tempDate.setMonth(tempDate.getMonth() + 1, 1)

        setDate(tempDate);        
    }

    const prevMonth = () => {
        let tempDate = new Date(date);
        tempDate.setMonth(tempDate.getMonth() - 1, 1)

        setDate(tempDate)
    }

    const formatDate = (dates) => {
        return new Date(dates).toLocaleString('default', {month: 'long'} ) + ' ' + new Date(dates).getFullYear()
    }

    const formatMonths = (dates) => {
        return '     ' + new Date(dates).toLocaleString('default', {month: 'short'} )
    }

    
    
    return (
        <div>
            <div className = "month">
                <div className="arrows prev-mth" onClick = {prevMonth}>&lt;{formatMonths(prevMonthDate)}</div>
                <div className="mth">
                {formatDate(date)}
                </div>
                <div className="arrows next-mth" onClick = {nextMonth}>{formatMonths(nextMonthDate)}&gt;</div>
            </div>
            <Days dateProp = {date} SelectedDateUpdate = {SelectedDateUpdate} 
            prevMonth = {prevMonth} nextMonth = {nextMonth} selectedDate = {selectedDate}
            />
        </div>
    )
}

Month.propTypes = {
    dateProp: PropTypes.instanceOf(Date).isRequired,
    SelectedDateUpdate: PropTypes.func.isRequired,
    updateDate: PropTypes.func.isRequired,
    monthUpdate: PropTypes.bool.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
}

export default Month
