const doLogin = async (email, password) => {
    const loginInfo = {
        user: {
            email,
            password
        }
    }

    const loginEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + '/user/login'
    
    let response = await fetch(
        loginEndpoint, {
            method:'POST',
            credentials:'include',
            headers: {
                Accept:'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(loginInfo)
        })

    if (response.ok) {
        return true;
    } else {
        console.log("HTTP ERROR: "+ response.status);
        //response.json().then(data => console.log(data.message));
        return response.json().then(data => data.message);
    }
}

export default doLogin;