import React, { useState } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';

function LinkShopifyApp() {
    const [shopifyShop, setShopifyShop] = useState('');
    const appLink = `${process.env.REACT_APP_SHOPIFY_ENDPOINT}/auth?shop=${shopifyShop}`;

    return(
        <>
            <h6>Get Shopify App</h6>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="<<your-shopify-store>>.myshopify.com"
                    value={shopifyShop}
                    onChange={(event)=> setShopifyShop(event.target.value)}
                />
                <InputGroup.Append>
                    <Button 
                    href={appLink}
                    target="_blank"
                    disabled={!shopifyShop}
                    >
                        Connect to my Shop
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </>         
    )
}

export default LinkShopifyApp;