import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Col, Row} from 'react-bootstrap';

import AddDeliveryController from './AddDeliveryController.component';

function AddDeliveryModal({user, deliveries, refreshDeliveries, children}) {
    const [showModal, setShowModal] = useState(false);
    const [backText, setBackText] = useState("Cancel");
    const [nextText, setNextText] = useState("Next");

    const [backDisabled, setBackDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(true);

    const [backEvent, setBackEvent] = useState(() => () => setShowModal(false));
    const [nextEvent, setNextEvent] = useState(() => () => console.log('next'));

    return(
        <>
            <div onClick={()=>setShowModal(true)}>
                {children}
            </div>

            <Modal 
                show={showModal} 
                onHide={()=>setShowModal(false)} 
                backdrop='static'
                size='lg'
            >
                <Modal.Header>
                    <Row>
                        <Col xs="auto">
                            <Modal.Title>Add Deliveries</Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <AddDeliveryController
                        user={user}
                        deliveries={deliveries}
                        refreshDeliveries={refreshDeliveries}
                        setBackText={setBackText}
                        setNextText={setNextText}
                        setBackDisabled={setBackDisabled}
                        setNextDisabled={setNextDisabled}
                        setBackEvent={setBackEvent}
                        setNextEvent={setNextEvent}
                        setOpen={setShowModal}
                        open={showModal}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="primary" 
                        onClick={backEvent}
                        disabled={backDisabled}
                    >
                        {backText}
                    </Button>
                    <Button 
                        variant="secondary" 
                        onClick={nextEvent}
                        disabled={nextDisabled}
                    >
                        {nextText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

AddDeliveryModal.propTypes = {
    user: PropTypes.object,
    deliveries: PropTypes.arrayOf(PropTypes.object),
    refreshDeliveries: PropTypes.func,
    children: PropTypes.element
}

export default AddDeliveryModal;