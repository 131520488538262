import React from 'react';
import PropTypes from 'prop-types';

import SidebarIcon from './SidebarIcon.component';
import { Row, Col, Accordion, Card } from 'react-bootstrap';

function SidebarItem({icon, title, children}) {
    return (
        <Accordion defaultActiveKey="0">
            <Card className="SidebarItem">
                <Accordion.Toggle as={Card.Header} eventKey="0" className="SidebarItemTitle">
                    <Row>
                        {(icon) ? <Col xs="auto"><SidebarIcon icon={icon}/></Col> : null}
                        <Col style={{fontSize: '16px'}}><span>{title}</span></Col>
                    </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <>{children}</>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

SidebarItem.propTypes = {
    icon: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element)
}

export default SidebarItem;