import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, ButtonGroup, Form} from 'react-bootstrap';

function AddPickupAddress({addAddress}) {

    const defaultNewAddress = {
        number: '',
        street: '',
        unit:'',
        city:'',
        province:"Ontario",
        country: "Canada",
        postalCode:''
    };

    const [open, setOpen] = useState(false);
    const [newAddress, setNewAddress] = useState(defaultNewAddress);
    const [streetAddress, setStreetAddress] = useState('');

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setNewAddress(defaultNewAddress);
        setStreetAddress('');

    }

    const isCompleteAddress = () => {
        return (
            newAddress.number && 
            newAddress.street && 
            newAddress.city && 
            newAddress.province && 
            newAddress.country && 
            newAddress.postalCode
        )
    }

    const handleAddressUpdate = (key, value) => {
        if(key==="streetAddress") {
            setStreetAddress(value);
            
            let split = value.trim().split(" ");
            let addressStreet = '';
            let addressNumber = split.shift();

            if (split[0]) {
                addressStreet = split.reduce((prev, current) => {  
                    return prev.concat(' ', current)
                });
            }

            const nextAddress = {
                ...newAddress, 
                number: addressNumber, 
                street: addressStreet
            }

            setNewAddress(nextAddress)
        }
        
        else setNewAddress({...newAddress, [key]: value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        addAddress(newAddress);
        handleClose();
    }

    return (
        <>
        {
            (open)
            ? (
                <Form 
                    onSubmit={handleSubmit} 
                    onReset={handleClose}
                >
                    <Row>
                        <Col xs='9'>
                            <Form.Group 
                                controlId="streetAddress" 
                                onChange={(event)=>handleAddressUpdate("streetAddress", event.target.value)}
                            >
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs='3'>
                            <Form.Group 
                                controlId="addressUnit" 
                                onChange={(event)=>handleAddressUpdate("unit", event.target.value)}
                            >
                                <Form.Label>Apt. / Unit</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newAddress.addressUnit}
        
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Form.Group 
                                controlId="city" 
                                onChange={(event)=>handleAddressUpdate("city", event.target.value)}
                            >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group 
                                controlId="province" 
                                onChange={(event)=>handleAddressUpdate("province", event.target.value)}
                            >
                                <Form.Label>Province</Form.Label>
                                <Form.Control 
                                    as="select"
                                    custom
                                    readOnly
                                    onChange={(event)=>handleAddressUpdate("province", event.target.value)}
                                    >
                                <option>Ontario</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group 
                                controlId="country" 
                                onChange={(event)=>handleAddressUpdate("country", event.target.value)}
                            >
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={newAddress.country}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group 
                                controlId="postalCode" 
                                onChange={(event)=>handleAddressUpdate("postalCode", event.target.value)}
                            >
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control 
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col align='right'>
                            <ButtonGroup>
                                <Button 
                                    type='reset'
                                    variant='primary'
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    type='submit' 
                                    variant='secondary'
                                    disabled={!isCompleteAddress()}
                                >
                                    Add Address
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row> 
                </Form>
            ) : (
                <Row>
                    <Col>
                        <Button data-testid='openAddress' variant='secondary' onClick={handleOpen}>
                            New Address
                        </Button>
                    </Col>
                </Row>
            )   
        }
        </>
    )
}

AddPickupAddress.propTypes = {
    addAddress: PropTypes.func.isRequired,
}

export default AddPickupAddress