import axios from 'axios';

const getDeliveryTracking = async (id) => {

    const endpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/deliveries/tracking/${id}` 

    try {
        const res = await axios.get(endpoint, {})
        let delivery = res.data.data;

        let formattedDelivery = {};

        formattedDelivery.id = delivery._id;
        formattedDelivery.sender = delivery.client.companyName;
        formattedDelivery.recipient = delivery.recipient.name;
        formattedDelivery.recipientPhone = delivery.recipient.phone;
        formattedDelivery.from = delivery.client.address;
        formattedDelivery.to = delivery.address;
        formattedDelivery.status = delivery.status; 
        formattedDelivery.options = delivery.options; 
        formattedDelivery.lastUpdated = delivery.updatedAt;

        formattedDelivery.driverNotes = (delivery.completionData.driverNotes) ? delivery.completionData.driverNotes : null;
        formattedDelivery.failureNotes = (delivery.completionData.failureNotes) ? delivery.completionData.failureNotes : null;
        formattedDelivery.failureReason = (delivery.completionData.failureReason) ? delivery.completionData.failureReason : null;
        formattedDelivery.completionPhotos = (delivery.completionData.photoLinks) ? delivery.completionData.photoLinks : null;
        formattedDelivery.completionSignature = (delivery.completionData.signatureLink) ? delivery.completionData.signatureLink : null;

        return formattedDelivery;
    } catch (err) {
        console.log(err)
        return null;
    }
}

export default getDeliveryTracking;