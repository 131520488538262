import React from 'react'; 
import PropTypes from 'prop-types';
import { Navbar, Nav} from 'react-bootstrap';

function HistoryPageFooter({children}) {
    return (
        <Navbar fixed="bottom" bg="primary" variant="dark">
            <Nav className="justify-content-between" style={{width:"100%"}}>
                {children}
            </Nav>
        </Navbar>
    )
}

HistoryPageFooter.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element)
}

export default HistoryPageFooter;