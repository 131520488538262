import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {Row, Col, Modal, Button} from 'react-bootstrap';

import LabelPrinter from './LabelPrinter.component';
import deleteDelivery from '../../services/deleteDelivery.service';

function DeliveryDetail({apiKey, forceOpen, delivery, refreshDeliveries, children, useLabelPrinter, recordSelected}) {
    const [showModal, setShowModal] = useState(false);
    const [cancelling, setCancelling] = useState(false);

    useEffect(() => {
        if(forceOpen) handleShow();
    },[forceOpen])

    const handleShow = () => {
        setShowModal(true);
        setCancelling(false);
    }

    const handleClose = () => {
        setShowModal(false);
        setCancelling(false);
    }

    const cancelDelivery = () => {
        deleteDelivery(delivery.id, apiKey).then(()=> {
            refreshDeliveries();
            handleClose();
        })
    }

    return (
        <>
            <div style={{fontSize: '18px'}} onContextMenu={(e) =>{
                e.preventDefault();
                handleShow();
            }} 
            onClick={() => recordSelected(delivery)}>
                {children}
            </div>
            <Modal
                show={showModal} 
                onHide={handleClose} 
            >
                <Modal.Header closeButton>
                    <Row>
                        <Col xs="auto">
                            <Modal.Title>{"Task " + delivery.id}</Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col><h5>Recipient</h5></Col></Row>
                    <Row><Col>{delivery.recipientName}</Col></Row>
                    <Row><Col>{delivery.recipientPhone}</Col></Row>
                    <hr/>
                    <Row><Col><h5>Destination</h5></Col></Row>
                    <Row><Col xs='auto'>
                        <span>
                            { (delivery.addressUnit) ? (`${delivery.addressUnit} - `) : (null) }
                            { `${delivery.addressNumber} ${delivery.addressStreet}` }   
                        </span>
                    </Col></Row>
                    <Row><Col>{`${delivery.city}, ${delivery.province}, ${delivery.country}`}</Col></Row>
                    <Row><Col>{delivery.postalCode}</Col></Row>
                    <hr/>
                    <Row><Col><h5>Pickup Address</h5></Col></Row>
                    <Row><Col xs='auto'>
                        <span>
                            { (delivery.clientAddressUnit) ? (`${delivery.clientAddressUnit} - `) : (null) }
                            { `${delivery.clientAddressNumber} ${delivery.clientAddressStreet}` }   
                        </span>
                    </Col></Row>
                    <Row><Col>{`${delivery.clientCity}, ${delivery.clientProvince}, ${delivery.clientCountry}`}</Col></Row>
                    <Row><Col>{delivery.clientPostalCode}</Col></Row>
                    <hr/>
                    <Row><Col><h5>Notes</h5></Col></Row>
                    <Row><Col><p>{delivery.deliveryNotes ? delivery.deliveryNotes :'N/A'}</p></Col></Row>
                    <Row><Col><i>Boxes: {delivery.quantity}</i></Col></Row>
                    {
                    (delivery.giftSender) 
                        ? <Row><Col><i>Gift Sender: {delivery.giftSender}</i></Col></Row>
                        : null
                    }
                    <hr/>
                    <Row><Col><h5>Status</h5></Col></Row>
                    <Row><Col><em>{delivery.status}</em></Col></Row>
                    <br/>
                    {
                        (delivery.status === 'completed') ? (
                            <>
                                <Row><Col>Photos:</Col></Row>
                                <Row>
                                    {
                                        (delivery.completionPhotos && delivery.completionPhotos[0]) 
                                        ? delivery.completionPhotos.map(photo => 
                                            <Col xs='3'>
                                                <a href={photo} target="_blank" rel="noopener noreferrer">
                                                <img src={photo} alt="proof of delivery" width='100%'/>
                                                </a>
                                            </Col>
                                        ) : <Col>(None)</Col>
                                    }
                                </Row>
                                <br/>
                                <Row><Col>Signature:</Col></Row>
                                <Row>
                                    {
                                        (delivery.completionSignature) ? (
                                        <Col>
                                            <img src={delivery.completionSignature} alt="signature of customer" width='60%'/>
                                        </Col>
                                        ) : <Col>(None)</Col>
                                    }
                                </Row>
                                <br/>
                                <Row><Col>Driver Notes:</Col></Row>
                                <Row>
                                {
                                    (delivery.completionNotes)
                                    ? <Col>{delivery.completionNotes}</Col>
                                    : <Col>(None)</Col>
                                }
                                </Row>
                            </>
                        ) : null
                    }
                    {
                        (delivery.status === 'failed') ? (
                            <>
                                <Row><Col>Photos:</Col></Row>
                                <Row>
                                    {
                                        (delivery.completionPhotos && delivery.completionPhotos[0]) 
                                        ? delivery.completionPhotos.map(photo => 
                                            <Col xs='3'>
                                                <img src={photo} alt="proof of failed delivery" width='100%'/>
                                            </Col>
                                        )
                                        : <Col>(None)</Col>
                                    }
                                </Row>
                                <br/>
                                <Row><Col>Failure Reason:</Col></Row>
                                <Row>
                                {
                                    (delivery.failureReason)
                                    ? <Col>{delivery.failureReason}</Col>
                                    : <Col>(None)</Col>
                                }
                                </Row>
                                <br/>
                                <Row><Col>Driver Notes:</Col></Row>
                                <Row>
                                {
                                    (delivery.failureNotes)
                                    ? <Col>{delivery.failureNotes}</Col>
                                    : <Col>(None)</Col>
                                }
                                </Row>
                            </>
                        ) : null
                    }
                    <LabelPrinter 
                        hideLabels='true' 
                        apiKey={apiKey} 
                        deliveries={[delivery]} 
                        useLabelPrinter={useLabelPrinter}
                    />    
                </Modal.Body>
                <Modal.Footer hidden={cancelling}>
                    <Row>
                        <Col>                            
                            <Button
                                variant="secondary"
                                disabled={!(delivery.status==="submitted" || delivery.status==="active")}
                                onClick={() => setCancelling(true)}

                            >
                                Cancel Delivery
                            </Button>

                            <Button 
                                variant="primary" 
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                    <br/>
                </Modal.Footer>
                <Modal.Footer hidden={!cancelling}>

                            <Row>
                                <Col>
                                    <em>Are you sure?</em>
                                    &nbsp; &nbsp;
                                    <Button
                                        variant="primary"
                                        onClick={()=>setCancelling(false)}
                                    >
                                        No
                                    </Button>

                                    <Button 
                                        variant="secondary" 
                                        onClick={cancelDelivery}
                                    >
                                        Yes
                                    </Button>
                                </Col>
                            </Row>


                </Modal.Footer>
            </Modal>
        </>
    )
}

DeliveryDetail.propTypes = {
    apiKey: PropTypes.string.isRequired,
    forceOpen: PropTypes.bool,
    delivery: PropTypes.object.isRequired,
    refreshDeliveries: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.element),
    useLabelPrinter: PropTypes.bool,
    recordSelected: PropTypes.func
}

export default DeliveryDetail;