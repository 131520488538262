import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ImportDeliveries from './ImportDeliveries.component';
import UploadDeliveries from './UploadDeliveries.component';
import PrintLabels from './PrintLabels.component';

import fetchZones from '../../../services/fetchZones.service';
import fetchClientRates from '../../../services/fetchClientRates.service';


function AddDeliveryController({
    user,
    deliveries,
    refreshDeliveries,
    setBackText, 
    setNextText, 
    setBackDisabled, 
    setNextDisabled, 
    setBackEvent, 
    setNextEvent,
    setOpen,
    open
}) {
    const [uploadPhase, setUploadPhase] = useState(0);
    const [nextDeliveries, setNextDeliveries] = useState([]);
    const [uploadedDeliveries, setUploadedDeliveries] = useState([]);
    const [deliveryZones, setDeliveryZones] = useState([]);

    useEffect(() => {
        const fetchDeliveryZones = async () => {
            const zones = await fetchZones();
            try {
                const rate = await fetchClientRates(user._id);
                if(rate){  
                    rate.customRates.forEach(customRate => {
                        zones.forEach(zone => {
                            if(customRate.zone._id.toString() === zone._id.toString()){
                                zone.price = customRate.price;
                            }
                        })
                    })
                }
            }
            catch (error) {
                // custom rate does not exist for user
            }
            setDeliveryZones(zones);
        }

        fetchDeliveryZones();
    },[open]);

    useEffect( () => {
        if (open) {
            if(uploadPhase === 0) {
                setNextEvent(()=>()=>setUploadPhase(1));
                setNextText("Next: Upload Items");
                setNextDisabled(nextDeliveries.length < 1);

                setBackEvent(()=>()=>setOpen(false))
                setBackText("Cancel");
                setBackDisabled(false);
            }
            if(uploadPhase === 1) {
                setNextEvent(()=>()=>setUploadPhase(2));
                setNextText("Next: Print Labels");
                setNextDisabled(uploadedDeliveries.length < 1);

                setBackEvent(()=>()=>setUploadPhase(0));
                setBackText("Back to Import");
                setBackDisabled(uploadedDeliveries.length > 0);
            }
            if(uploadPhase === 2) {
                setNextEvent(()=>()=>setOpen(false));
                setNextText("Complete");
                setNextDisabled(false);
                
                setBackEvent(()=>()=>setUploadPhase(1));
                setBackText("Back to Upload");
                setBackDisabled(false);
            }
        }
    }, [open, uploadPhase, nextDeliveries, uploadedDeliveries]);

    return (
        <>
        {
            (uploadPhase === 0) 
            ? 
                <ImportDeliveries
                    user={user}
                    nextDeliveries={nextDeliveries}
                    setNextDeliveries={setNextDeliveries}
                />
               
            : (uploadPhase === 1)
            ?
                <UploadDeliveries
                    user={user}
                    nextDeliveries={nextDeliveries}
                    setNextDeliveries={setNextDeliveries}
                    uploadedDeliveries={uploadedDeliveries}
                    setUploadedDeliveries={setUploadedDeliveries}
                    refreshDeliveries={refreshDeliveries}
                    deliveryZones={deliveryZones}
                />
            : (uploadPhase === 2)
            ?
                <PrintLabels
                    apiKey={user.apiKey}
                    useLabelPrinter={user.preferences.useLabelPrinter}
                    uploadedDeliveries={nextDeliveries}
                />
            : null

        }
        </>
    )
}

AddDeliveryController.propTypes = {
    user: PropTypes.object,
    deliveries: PropTypes.arrayOf(PropTypes.object),
    refreshDeliveries: PropTypes.func,
    setBackText: PropTypes.func,
    setNextText: PropTypes.func,
    setBackDisabled: PropTypes.func,
    setNextDisabled: PropTypes.func,
    setBackEvent: PropTypes.func,
    setNextEvent: PropTypes.func,
    setOpen: PropTypes.func,
    open: PropTypes.bool
}

export default AddDeliveryController;