import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Marker} from 'google-maps-react';

import awaitingPickupMarker from '../../../assets/map-markers/awaiting-pickup.svg';
import deliveredMarker from '../../../assets/map-markers/delivered.svg';
import failedMarker from '../../../assets/map-markers/failed.svg';
import inTransitMarker from '../../../assets/map-markers/in-transit.svg';
import submittedMarker from '../../../assets/map-markers/submitted.svg';
import readyMarker from '../../../assets/map-markers/pickup-ready.svg';


import DeliveryDetail from '../../home/DeliveryDetail.component';

function MoreDetailsMapMarker ({google, map, user, delivery}) {
  const [clicked, setClicked] = useState(false);

  const transmitClick = () => {
    setClicked(true);
    setClicked(false);
  }
  
  const markerIcons = {
    active: {
      icon: {
        url: awaitingPickupMarker,
        scaledSize: new google.maps.Size(22, 22)
      }   
    },
    completed: {
      icon: {
        url: deliveredMarker,
        scaledSize: new google.maps.Size(22, 22)
      }
    },    
    failed: {
      icon: {
        url: failedMarker,
        scaledSize: new google.maps.Size(22, 22)
      }
    },    
    inProgress: {
      icon: {
        url: inTransitMarker,
        scaledSize: new google.maps.Size(22, 22)
      }
    },    
    submitted: {
      icon: {
        url: submittedMarker,
        scaledSize: new google.maps.Size(22, 22)
      }
    },
    pickupReady: {
      icon: {
        url: readyMarker, 
        scaledSize: new google.maps.Size(22, 22)
      }
    }
  };

  return (
    <DeliveryDetail
    delivery={delivery}
    apiKey={user.apiKey}
    forceOpen={clicked}
    useLabelPrinter={!!(user.preferences.useLabelPrinter)}
    >
      <Marker
          {...{google, map}}
          title={delivery.recipientName}
          position={delivery.coordinates}
          icon={markerIcons[delivery.status].icon }
          onClick={transmitClick}

      />
    </DeliveryDetail>
  )
}

MoreDetailsMapMarker.propTypes = {
  google: PropTypes.any.isRequired,
  map: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  delivery: PropTypes.object.isRequired,
  refreshDeliveries: PropTypes.func.isRequired
}

export default MoreDetailsMapMarker