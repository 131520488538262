const axios = require('axios').default;

const fetchClientRates = async (userId) => {
  const ratesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/customRate/${userId}`
  const result = await axios.get(ratesEndpoint);
  
  const rates = result.data.data;
  return rates;
}

export default fetchClientRates;