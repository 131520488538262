import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map as GMap, GoogleApiWrapper } from 'google-maps-react';

import MoreDetailsMapMarker from './MoreDetailsMapMarker.component';
import mapStyles from '../../../styles/mapStyles.json'

const mapSizing = {
    width: '20vw',
    height: '20vh',
};

function MoreDetailsMap({google, user, delivery}) {
  return (
    <div className="GMapContainer">
        <GMap
            className="moreDetailsMap"
            google={google}
            styles={mapStyles}
            disableDefaultUI={true}
            zoom={11}
            style={mapSizing}
            initialCenter ={ 
                {
                lat: delivery.coordinates.lat,
                lng: delivery.coordinates.lng
              }
            }
            center={
                {
                    lat: delivery.coordinates.lat,
                    lng: delivery.coordinates.lng
                }
            }
        >
               <MoreDetailsMapMarker 
                  google={google}
                  map={Map}
                  delivery={delivery} 
                  user={user}
                />
        </GMap>
    </div>
  );
}

MoreDetailsMap.propTypes = {
  google: PropTypes.any.isRequired,
  user:PropTypes.object.isRequired,
  refreshDeliveries: PropTypes.func.isRequired,
  deliveries: PropTypes.arrayOf(PropTypes.object),
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(MoreDetailsMap);