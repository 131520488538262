import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Modal, Button} from 'react-bootstrap';

import doLogout from '../../services/doLogout.service';

function Logout({children, checkIsLoggedIn}) {
    const [showModal, setShowModal] = useState(false);
    
    const logout = async () => {
        doLogout().then(checkIsLoggedIn);
    }

    return (
        <>
            <div onClick={() => setShowModal(true)}>
                {children}
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)} 
            >
                <Modal.Header closeButton>
                    <Row>
                        <Col xs="auto">
                            <Modal.Title>Are you sure you want to log out?</Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Footer>
                    <Button 
                            variant="primary" 
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="secondary" 
                            onClick={logout}
                        >
                            Log Out
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

Logout.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    checkIsLoggedIn: PropTypes.func.isRequired,
}

export default Logout;