import React from 'react'; 
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'react-bootstrap';

function PaginationControls ({page, lastPage, setPage}) {

    const onFirstPage = (page === 0);
    const onLastPage  = (page === lastPage);

    const prevPage = page - 1;
    const nextPage = page + 1;

    return (
        <ButtonGroup>
            <Button
                variant='secondary'
                disabled={onFirstPage}
                onClick={() => setPage(0)}
            >
                First
            </Button>
            <Button
                variant='secondary'
                disabled={onFirstPage}
                onClick={() => setPage(prevPage)}
            >
                Prev
            </Button>
            <Button disabled>{page + 1}</Button> 
            <Button
                variant='secondary'
                disabled={onLastPage}
                onClick={() => setPage(nextPage)}
            >
                Next
            </Button>
            <Button
                variant='secondary'
                disabled={onLastPage}
                onClick={() => setPage(lastPage)}
            >
                Last
            </Button>
        </ButtonGroup>
    );
};

PaginationControls.propTypes = {
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired
};

export default PaginationControls;