import React from 'react';
import PropTypes from 'prop-types';
import { Col, OverlayTrigger, Popover } from 'react-bootstrap';

function CurrentDeliveryStatusIndicator({status}) {
    const statuses = {
        submitted: {
            style: "StatusIndicator submitted"
        },
        pickupReady: {
            style: "StatusIndicator pickup-ready"
        },
        active: {
            style: "StatusIndicator awaiting-pickup"
        },
        inProgress: {
            style: "StatusIndicator in-transit"
        },
        completed: {
            style: "StatusIndicator delivered"
        },
        failed: {
            style: "StatusIndicator failed"
        }
    }

    return (
        <Col style={{display:'inline', marginLeft: '-0.3vw'}}>
            <div className={statuses[status].style}/>
        </Col>
    )
}

CurrentDeliveryStatusIndicator.propTypes = {
    status: PropTypes.string
}

export default CurrentDeliveryStatusIndicator;