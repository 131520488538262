import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form, FormCheck} from 'react-bootstrap';

import doChangeUserInfo from '../../services/doChangeUserInfo.service';

function SelectSmartServe ({preferences, refreshUser}) {
    const [useSmartServe, setUseSmartServe] = useState((preferences && preferences.useSmartServe));

    useEffect(() => {
        setUseSmartServe((preferences && preferences.useSmartServe));
    }, [preferences]);

    const toggleSmartServe = () => {
        doChangeUserInfo({'preferences.useSmartServe': !useSmartServe})
        .then(refreshUser);
    }

return (
    <>
        <h6>Smart Serve</h6>
        <Form>
            <Row>
                <Col>
                    <FormCheck
                        type='switch'
                        id='default-smartServe'
                        label={
                            (useSmartServe)
                            ? (<em data-testid='smartServeTextOn'>Smart Serve default state is on.</em>)
                            : (<em data-testid='smartServeTextOff'>Smart Serve default state is off.</em>)
                        }
                        checked={useSmartServe}
                        onChange={toggleSmartServe}
                    />  
                </Col>
            </Row>
        </Form>
    </>         
)
}

SelectSmartServe.propTypes = {
    preferences: PropTypes.object,
    refreshUser: PropTypes.func.isRequired
}
export default SelectSmartServe