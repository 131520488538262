import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';

function DeliverySubmenuItem({status, name, address, selected}) {
    const statuses = {
        submitted: {
            message: "Received, is currently pending dispatch", /* READYFORPICKUP DISABLE */
            style: "StatusIndicator submitted"
        },
        pickupReady: {
            message: "Received, is currently pending dispatch",
            style: "StatusIndicator pickup-ready"
        },
        active: {
            message: "Currently in queue for delivery",
            style: "StatusIndicator awaiting-pickup"
        },
        inProgress: {
            message: "Currently on its way",
            style: "StatusIndicator in-transit"
        },
        completed: {
            message: "Successfully delivered",
            style: "StatusIndicator delivered"
        },
        failed: {
            message: "Failed for an unknown reason. please contact support for more information",
            style: "StatusIndicator failed"
        }
    }


    const statusOverlay = (
        <Popover>
            <Popover.Title as="h3">
                {statuses[status].message}
            </Popover.Title>
        </Popover>
    )

    return (
        <ListGroup.Item className={selected ? "Submenu selected p-1 newHover" : "Submenu p-1"}>
            <Row>
                <Col>
                    <strong>{name}</strong>
                </Col>
                <OverlayTrigger 
                    trigger={['hover', 'focus']}
                    placement="right" 
                    overlay={statusOverlay}
                >

                    <Col xs="auto" align="right">
                        <div className={statuses[status].style}/>
                    </Col>
                </OverlayTrigger>
            </Row>
            <Row>
                <Col>
                    <em>
                        {address}
                    </em>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}

DeliverySubmenuItem.propTypes = {
    status: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.object,
    selected: PropTypes.bool
}

export default DeliverySubmenuItem;