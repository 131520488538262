import axios from 'axios';

const fetchDeliveryHistory = async (apiKey, page=0, limit=null, startDate, endDate) => {
    let endpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/deliveries/list/${page}/${(limit) ? limit : ''}` 

    try {
        const config = (startDate && endDate) ? 
        {
            headers: {'X-API-Key': apiKey},
            params: {
                startDate,
                endDate
            },
        } :
        {
            headers: {'X-API-Key': apiKey}
        }

        const res = await axios.get(endpoint, config)
        let deliveries = res.data.data.deliveries;
        let numPages = res.data.data.numPages;

        let formattedDeliveries = deliveries.map( delivery => {
            //determine status from status timestamps 
            let status;
            if (delivery.status.failed || delivery.status.completed) {
                if (delivery.status.failed)         {status = 'failed';}
                if (delivery.status.completed)      {status = 'completed';}   
            }

            else if (delivery.status.inProgress)    {status = 'inProgress';}

            else if (delivery.status.active) {
                let activeTime = new Date(delivery.status.active)
                let submittedTime = new Date(delivery.status.submitted)
                status = ( activeTime > submittedTime ) 
                ?  'active'
                :  'submitted'
            }
            else if (delivery.status.submitted)      { status = 'submitted' }

            let formattedDelivery = {
                
                recipientName: delivery.recipient.name,
                
                recipientPhone: delivery.recipient.phone,
                
                address:`${(delivery.address.unit) ? delivery.address.unit + ' - ': ''}` +
                        `${delivery.address.number} ${delivery.address.street}, \n` +
                        `${delivery.address.city}, ${delivery.address.province}, ${delivery.address.country} \n` +
                        `${delivery.address.postalCode}`, 

                quantity: (delivery.options) ? delivery.options.quantity : null,
                
                giftSender: (delivery.options) ? delivery.options.giftSender : null,

                notes: (delivery.options) ? delivery.options.deliveryNotes : null,

                status: status,

                photos: (delivery.completionData && delivery.completionData.photoLinks && delivery.completionData.photoLinks[0]) 
                    ? delivery.completionData.photoLinks
                    : null,
                
                signature: (delivery.completionData && delivery.completionData.signatureLink)
                    ? delivery.completionData.signatureLink
                    : null,

                completionNotes: (delivery.completionData) 
                    ? (delivery.completionData.failureNotes) 
                        ? `${delivery.completionData.failureReason}: ${delivery.completionData.failureNotes}`
                        : delivery.completionData.driverNotes
                    : null,

                submitted: new Date(Date.parse(delivery.status.submitted)).toLocaleString(),
                active: new Date(Date.parse(delivery.status.active)).toLocaleString(),
                inProgress: new Date(Date.parse(delivery.status.inProgress)).toLocaleString(),
                completed: new Date(Date.parse(delivery.status.completed)).toLocaleString(),
                failed: new Date(Date.parse(delivery.status.failed)).toLocaleString(),

                id: delivery._id,
                onfleetId: delivery.onfleetId,
                onfleetShortId: delivery.onfleetShortId,
            }
            return formattedDelivery
        })

        return {deliveries: formattedDeliveries, numPages: numPages}
    } catch (err) {
        console.log(err)
        return null;
    }
}

export default fetchDeliveryHistory;