const axios = require('axios').default;

const checkPackageType = async (dimensions) => {
  const packageEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/packageType`
  try {
    const res = await axios.post(
        packageEndpoint, 
        {dimensions: dimensions},
        {}
    )
    const priceMultiplier = res.data.data.priceMultiplier;
    return priceMultiplier;

    } catch (err) {
        console.log(err);
        return null;
    }

}

export default checkPackageType;