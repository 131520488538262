const axios = require('axios').default;

const fetchZones = async () => {
  const zonesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/`
  const result = await axios.get(zonesEndpoint);
  
  const zones = result.data.data
    .map(zone => ({
        _id: zone._id,
        title: zone.title, 
        zone: zone.zone,
        subzone: zone.subzone,
        price: zone.price, 
        postalCodes:zone.postalCodes
    }));
  return zones;
}

export default fetchZones;