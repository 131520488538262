import React from 'react';
import HistoryIcon from '../../../assets/pickup.svg'
import SidebarItem from './SidebarItem.component';

function CurrentDeliveriesButton() {
    return (
        <a href='/current-deliveries'>
            <SidebarItem icon={{img:HistoryIcon, alt:"current deliveries"}} title="Current Deliveries" eventKey="current deliveries">
            </SidebarItem>
        </a>
    )
}

export default CurrentDeliveriesButton;