import React from 'react';
import PropTypes from 'prop-types';
import { Map as GMap, GoogleApiWrapper } from 'google-maps-react';

import MapMarker from './MapMarker.component';
import mapStyles from '../../../styles/mapStyles.json'

const mapSizing = {
    width: '85%',
    height: '100%',
};

function Map({google, user, deliveries, refreshDeliveries}) {
  return (
    <div className="GMapContainer">
        <GMap
            className="GMap"
            google={google}
            styles={mapStyles}
            disableDefaultUI={true}
            zoom={11}
            style={mapSizing}
            initialCenter={
                {
                    lat: 43.6532,
                    lng: -79.4032
                }
            }
        >
          {
            (deliveries)
            ? deliveries.map(delivery => (
                <MapMarker 
                  delivery={delivery} 
                  user={user}
                  refreshDeliveries={refreshDeliveries}
                />
              ))
            : null
          }
        </GMap>
    </div>
  );
}

Map.propTypes = {
  google: PropTypes.any.isRequired,
  user:PropTypes.object.isRequired,
  refreshDeliveries: PropTypes.func.isRequired,
  deliveries: PropTypes.arrayOf(PropTypes.object),
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(Map);