import React, { Component } from 'react';
import {Row, Col, Modal, Button } from 'react-bootstrap';

import CopyApiKey from './CopyApiKey.component'; 
import SelectPrintMethod from './SelectPrintMethod.component';
import SelectReadyForPickup from './SelectReadyForPickup.component';
import LinkShopifyApp from './LinkShopifyApp.component';
import SelectSmartServe from './SelectSmartServe.component';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state={
            showModal: false,
            copied: ''
        }
    }

    handleShow = () => {
        this.setState({
            showModal:true
        });
    }

    handleClose = () => {
        this.setState({
            showModal:false, 
        });
    }

    /* READYFORPICKUP DISABLE
                                <SelectReadyForPickup preferences={this.props.user.preferences} refreshUser={this.props.refreshUser}/>
                            <br/>
    */

    render() {
        return(
            <div>
                <div onClick={this.handleShow}>
                    {this.props.children}
                </div>
                <Modal
                    show={this.state.showModal} 
                    onHide={this.handleClose} 
                    size='md'
                >
                    <Modal.Header closeButton>
                        <Row>
                            <Col xs="auto">
                                <Modal.Title>Settings</Modal.Title>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                            <SelectPrintMethod preferences={this.props.user.preferences} refreshUser={this.props.refreshUser}/>
                            <br/>
                            <SelectSmartServe preferences={this.props.user.preferences} refreshUser={this.props.refreshUser}/>
                            <br/>                            
                            <LinkShopifyApp/>
                            <br/>
                            <CopyApiKey apiKey={this.props.user.apiKey}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                                variant="primary" 
                                onClick={this.handleClose}
                            >
                                Done
                            </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        )
    }
}

export default Settings