import React from 'react';
import PropTypes from 'prop-types';
import { Row, Table, Card, Spinner } from 'react-bootstrap';

function DeliveryList({ deliveries }) {

    let deliveriesList = (deliveries) ? deliveries.map(delivery => (            
            <tr>
                <td>{delivery.recipientName}</td>
                <td>{delivery.recipientPhone}</td>
                <td>{delivery.address}</td>
                <td>{delivery.giftSender ? delivery.giftSender : 'N/A'}</td>
                <td>{delivery.quantity}</td>
                <td>{delivery.notes}</td>
                <td>{delivery.status}</td>
                <td>{delivery[delivery.status]}</td>

                <td>
                    {
                        (delivery.status === "completed" || delivery.status === "failed") 
                            ? (delivery.photos && delivery.photos[0])
                                ? delivery.photos.map((photo, index) => (
                                    <>
                                        { (index > 0) ? <br/> : null }
                                        <a 
                                        href={photo} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        >
                                            Photo&nbsp;{index+1}&nbsp;&#x27A4;
                                        </a>
                                    </>
                                ))
                                : <em>No photos</em>
                            : ''
                    }
                </td>
                <td>{delivery.completionNotes}</td>
                <td>{(delivery.onfleetShortId) ? delivery.onfleetShortId : delivery.id}</td>
            </tr>
        )
    ) : []

    return (
        <Row className='wrapper'>
            <Card className="HistoryTable">

                {
                    (!deliveries) ? (
                        <div align='center'>
                            <Spinner
                                animation='border'
                                role='status'
                                variant='secondary'
                                align='center'
                                className='p-3 m-3'
                            />
                        </div>
                    ) : (
                        <Table bordered hover striped>
                            <thead>
                                <tr>
                                    <th>Recipient</th>
                                    <th>Phone</th>
                                    <th>Address</th>
                                    <th>Gift Sender</th>
                                    <th>Quantity</th>
                                    <th>Notes</th>
                                    <th>Status</th>
                                    <th>Time</th>
                                    <th>Photos</th>
                                    <th>Driver Notes</th>
                                    <th>ID</th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                { deliveriesList }
                            </tbody>
                        </Table>
                    )
                }
            </Card>
        </Row>
    )
};

DeliveryList.propTypes = {
    deliveries: PropTypes.arrayOf(PropTypes.object).isRequired
};


export default DeliveryList;