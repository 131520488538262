import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect, useParams} from 'react-router-dom'

import Home from '../home/Home.component';
import History from '../history/History.component';
import CurrentDeliveries from '../current-deliveries/CurrentDeliveries.component';
import MoreDetails from '../current-deliveries/more-details/MoreDetails.component';
import Layout from './Layout.component';
import Login from '../login/Login.component';
import DeliveryTracking from '../tracking/DeliveryTracking.component';

import fetchUserInfo from '../../services/fetchUserInfo.service';

function AppRouter() {
    const [loggedIn, setLoggedin] = useState(true);


    const checkIsLoggedIn = async () => {
        const result = await fetchUserInfo();
        if (!result) setLoggedin(false);
        else {
            const user = result;
            if (user) setLoggedin(true);
            else setLoggedin(false);
        };
    };

    checkIsLoggedIn();

    return (
        <Router>
            <Switch>
                <Route path='/login' render={ () => (
                        loggedIn
                        ? (<Redirect to="/"/>)
                        : (<Login checkIsLoggedIn={checkIsLoggedIn}/>)
                    )} />

                <Route path='/'>
                <Layout>
                    <Switch>
                        <Route exact path='/' render={ () => (
                            loggedIn 
                            ? (<Home checkIsLoggedIn={checkIsLoggedIn}/>) 
                            : (<Redirect to='/login'/>) 
                            )}
                        />

                        <Route path='/history' render={ () => (
                            loggedIn 
                            ? (<History checkIsLoggedIn={checkIsLoggedIn} />) 
                            : (<Redirect to='/login'/>) 
                            )}
                        />

                        <Route path='/current-deliveries' render={ () => (
                            loggedIn 
                            ? (<CurrentDeliveries checkIsLoggedIn={checkIsLoggedIn} />) 
                            : (<Redirect to='/login'/>) 
                            )}
                        />

                        <Route path = '/tracking/:id' render= {() => (
                            <Tracking/>
                        )}
                        />
                    </Switch>
                </Layout>
                </Route>
            </Switch>
        </Router>
    );
}

function Tracking() {
    let {id} = useParams();
    document.body.style = 'background-color: #e7e8ef;';
    document.title = 'Tayza Delivery Tracking'
    return <DeliveryTracking deliveryId={id}></DeliveryTracking>
}
export default AppRouter;