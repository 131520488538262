import React from 'react';
import PropTypes from 'prop-types';

import { useState, useEffect} from 'react'
const TimePicker = ({TimeUpdate, selectedDate}) => {
    const [hours, setHours] = useState(selectedDate.getHours())
    const [minutes, setMinutes] = useState(selectedDate.getMinutes())
    const [am, setAm] = useState(true)
  
    //AM-PM
    useEffect(() => {
        
      if(hours > 11){
            setAm(false)
        }
    }, [])

    const switchAmPm = () => {
        if(am){
            setHours(hours + 12)
        }
        else {
            setHours(hours-12)
        }
        setAm(!am)
    }

    //Mins
    useEffect(() => {
        if(minutes > 59) {
            setMinutes(0)
            setHours(hours + 1)
        }
        if(minutes < 0){
            setMinutes(59)
            setHours(hours - 1)
        }
        TimeUpdate(hours, minutes)
        
    }, [minutes])

    const formatMinutes = (min) => {
        if(min < 10) {
            min = min.toString();
            min = '0' + min;
        }
        return min;
    }

    //Hours
    useEffect(() => {
        if(hours > 23) {
            setHours(0)
        }
        if(hours < 0){
            setHours(23)
        }
        if(hours >= 12)
        {
            setAm(false)
        }
        else {
            setAm(true)
        }
        TimeUpdate(hours, minutes)
    }, [hours])

    const amPmHour = (hr) => {
        if(hr > 12){
            return hr-12;
        }
        if(hr === 0 ){
            return 12;
        }
        return hr;
    }

    const formatHour = (hour) => {
        let AMPMHour = amPmHour(hour)
      if( AMPMHour < 10){
            AMPMHour = AMPMHour.toString();
            AMPMHour = '0' + AMPMHour;  
      }  
      return AMPMHour;
    }

    return (
        <div>
            <i className="arrow up hr" id = 'hrUp' onClick = {() => setHours(hours+1)}></i> 
            <i className="arrow up min" id = 'minUp' onClick = {() => setMinutes(minutes + 1)}></i>
                <div>
                    <div className = 'time-display'>{`${formatHour(hours)}    :    ${formatMinutes(minutes)}`}</div>
                    <label className="switch">
                        <input type= "checkbox" onClick = {switchAmPm} checked = {am}/>
                        <div className="slider"></div>
                        <div className="AMPM"></div>
                    </label>
                </div>
            <i className="arrow down hr" id = 'hrDown'onClick = {() => setHours(hours-1)}></i> 
            <i className="arrow down min" id = 'minDown' onClick = {() => setMinutes(minutes - 1)}></i>
        </div>
    )
}

TimePicker.propTypes = {
    TimeUpdate: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired
}

export default TimePicker
