import React, { useState, useEffect } from 'react';
import Layout from '../_common/Layout.component';

import CurrentDeliveriesHeader from './CurrentDeliveriesHeader.component';
import CurrentDeliveriesFilter from './CurrentDeliveriesFilter.component';
import CurrentDeliveriesList from './CurrentDeliveriesList.component';
import CurDelPaginationControls from './CurrentDeliveriesPagination.component';
import MoreDetails from './more-details/MoreDetails.component';

import fetchUserInfo from '../../services/fetchUserInfo.service';
import fetchFilteredDeliveries from '../../services/fetchFilteredDeliveries.service';
import fetchFilteredDeliveriesAndGiftSenders from '../../services/fetchFilteredDeliveriesAndGiftSenders.service';

import doChangeUserInfo from '../../services/doChangeUserInfo.service';


import { Col } from 'react-bootstrap';

function CurrentDeliveries() {
    const [user, setUser] = useState(null);
    const [deliveries, setDeliveries] = useState([]);
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);
    const [selectedPickUp, setSelectedPickUp] = useState([]);
    const [selectedGiftSenders, setSelectedGiftSenders] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sortByFilter, setSortByFilter] = useState('');
    const [pageLimit, setPageLimit] = useState(50);
    const [numDeliveries, setNumDeliveries] = useState(0);
    const [giftSenders, setGiftSenders] = useState([])
    const [showMoreDetails, setShowMoreDetails] = useState(false)
    const [moreDetailsDelivery, setMoreDetailsDelivery] = useState([])
    const [moreDetailsUpdate, setMoreDetailsUpdate] = useState(false)

    useEffect(() => {
        if (user) {
            setPageLimit(user.preferences.deliveryPageLimit);
            refreshDeliveriesAndGiftSenders();
        }
        else {
            refreshUser();
        }
    }, [user])

    useEffect(() => {
        refreshDeliveries();
    }, [page]);

    useEffect(() => {
        if (moreDetailsUpdate === true){
            console.log("bruh")
            refreshDeliveries();
        }
        setMoreDetailsUpdate(false)
        console.log(moreDetailsUpdate)
    }, [moreDetailsUpdate]);

    useEffect(() => {
        refreshDeliveries();
        setPage(0);
    }, [selectedStatus, selectedZones, selectedPickUp, selectedGiftSenders, startDate, endDate, sortByFilter])

    useEffect(() => {
        refreshDeliveries();
        setPage(0);
        if (user) {
            doChangeUserInfo({'preferences.deliveryPageLimit': pageLimit})
        }
    }, [pageLimit]);

    const refreshDeliveriesAndGiftSenders = async () => {
        if (user) {
            try {
                const result = await fetchFilteredDeliveriesAndGiftSenders(user.apiKey, selectedStatus, selectedZones, selectedPickUp, selectedGiftSenders, startDate, endDate, sortByFilter, page, pageLimit)

                setLastPage((result.numDeliveries % pageLimit === 0) ? Math.floor(result.numDeliveries / pageLimit) - 1 : Math.floor(result.numDeliveries / pageLimit));
                setNumDeliveries(result.numDeliveries)
                setDeliveries(result.formattedDeliveries);
                setGiftSenders(result.giftSendersList)
            }
            catch (err) {
                console.log(err);
            }
        }
    }

    const refreshDeliveries = async () => {
        if (user) {
            try {
                const result = await fetchFilteredDeliveries(user.apiKey, selectedStatus, selectedZones, selectedPickUp, selectedGiftSenders, startDate, endDate, sortByFilter, page, pageLimit)

                setLastPage((result.numDeliveries % pageLimit === 0) ? Math.floor(result.numDeliveries / pageLimit) - 1 : Math.floor(result.numDeliveries / pageLimit));
                setNumDeliveries(result.numDeliveries)
                setDeliveries(result.formattedDeliveries);
            }
            catch (err) {
                console.log(err);
            }
        }
    }

    const refreshUser = async () => {
        const user = await fetchUserInfo();
        setUser(user);
    }

    return (
        <Layout style={{ overflow: 'hidden' }}>
            { (showMoreDetails) ?
            <MoreDetails 
                setShowMoreDetails={setShowMoreDetails}
                moreDetailsDelivery={moreDetailsDelivery}
                setMoreDetailsDelivery={setMoreDetailsDelivery}
                deliveries={deliveries}
                user={user}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setSortByFilter={setSortByFilter}
                setMoreDetailsUpdate={setMoreDetailsUpdate}
            />
            :
            <>
            <CurrentDeliveriesHeader />
            <Col xs={3} style={{ float: 'left' }}>
                <CurrentDeliveriesFilter
                    user={user}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    selectedZones={selectedZones}
                    setSelectedZones={setSelectedZones}
                    selectedPickUp={selectedPickUp}
                    setSelectedPickUp={setSelectedPickUp}
                    selectedGiftSenders={selectedGiftSenders}
                    setSelectedGiftSenders={setSelectedGiftSenders}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    numDeliveries={numDeliveries}
                    giftSenders={giftSenders}
                />
            </Col>
            <Col xs={9} style={{ display: 'inline', float: 'left', paddingLeft: '2%' }}>
                <CurrentDeliveriesList
                    deliveries={deliveries}
                    setDeliveries={setDeliveries}
                    user={user}
                    refreshDeliveriesAndGiftSenders={refreshDeliveriesAndGiftSenders}
                    page={page}
                    lastPage={lastPage}
                    setPage={setPage}
                    setSortByFilter={setSortByFilter}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    numDeliveries={numDeliveries}
                    setShowMoreDetails={setShowMoreDetails}
                    setMoreDetailsDelivery={setMoreDetailsDelivery}
                    moreDetailsDelivery={moreDetailsDelivery}
                />
            </Col>
            </>
            }   
        </Layout>
    );
}

export default CurrentDeliveries;
